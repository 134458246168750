import { Setting } from "./ProjectConfig";
/**
 * Specifies the behaviours for flag overrides.
 */
export var OverrideBehaviour;
(function (OverrideBehaviour) {
  /**
   * When evaluating values, the SDK will not use feature flags and settings from the ConfigCat CDN, but it will use
   * all feature flags and settings that are loaded from local-override sources.
   */
  OverrideBehaviour[OverrideBehaviour["LocalOnly"] = 0] = "LocalOnly";
  /**
   * When evaluating values, the SDK will use all feature flags and settings that are downloaded from the ConfigCat CDN,
   * plus all feature flags and settings that are loaded from local-override sources. If a feature flag or a setting is
   * defined both in the fetched and the local-override source then the local-override version will take precedence.
   */
  OverrideBehaviour[OverrideBehaviour["LocalOverRemote"] = 1] = "LocalOverRemote";
  /**
   * When evaluating values, the SDK will use all feature flags and settings that are downloaded from the ConfigCat CDN,
   * plus all feature flags and settings that are loaded from local-override sources. If a feature flag or a setting is
   * defined both in the fetched and the local-override source then the fetched version will take precedence.
   */
  OverrideBehaviour[OverrideBehaviour["RemoteOverLocal"] = 2] = "RemoteOverLocal";
})(OverrideBehaviour || (OverrideBehaviour = {}));
var MapOverrideDataSource = /** @class */function () {
  function MapOverrideDataSource(map, watchChanges) {
    this.initialSettings = this.constructor.getCurrentSettings(map);
    if (watchChanges) {
      this.map = map;
    }
  }
  MapOverrideDataSource.getCurrentSettings = function (map) {
    return Object.fromEntries(Object.entries(map).map(function (_a) {
      var key = _a[0],
        value = _a[1];
      return [key, Setting.fromValue(value)];
    }));
  };
  MapOverrideDataSource.prototype.getOverrides = function () {
    return Promise.resolve(this.getOverridesSync());
  };
  MapOverrideDataSource.prototype.getOverridesSync = function () {
    return this.map ? this.constructor.getCurrentSettings(this.map) : this.initialSettings;
  };
  return MapOverrideDataSource;
}();
export { MapOverrideDataSource };
var FlagOverrides = /** @class */function () {
  function FlagOverrides(dataSource, behaviour) {
    this.dataSource = dataSource;
    this.behaviour = behaviour;
  }
  return FlagOverrides;
}();
export { FlagOverrides };