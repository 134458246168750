<div class="firewall-policy-list">
    <div class="breadcrumb-section">
        <div class="col-xl-12 p-0">
            <ol class="breadcrumb mb-4">
                <li class="breadcrumb-item">
                    <a class="text-muted font-weight-bolder"
                       routerLink='/settings'>Settings</a>
                </li>
                <li class="breadcrumb-item active"
                    *ngIf="view === 'policy'">
                    <span class="text-muted font-weight-bolder">Firewall</span>
                </li>
                <ng-container *ngIf="view === 'rule'">
                  
                  
                    <li class="breadcrumb-item cursor-pointer">
                        <a class="text-muted font-weight-bolder"
                           (click)="onFirewallNavigate()">Firewall</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <span class="text-muted font-weight-bolder">{{generateNameFromEnum(selectedPolicy.ruleType.name, IRuleTypeTitle)}}</span>
                    </li>
                </ng-container>
                <ng-container *ngIf="view === 'pastRule'">
                    <li class="breadcrumb-item cursor-pointer">
                        <a class="text-muted font-weight-bolder"
                           (click)="onFirewallNavigate()">Firewall</a>
                    </li>
                    <li class="breadcrumb-item cursor-pointer">
                        <a class="text-muted font-weight-bolder"
                           (click)="view = 'rule'">{{generateNameFromEnum(selectedPolicy.ruleType.name,
                            IRuleTypeTitle)}}</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <span class="text-muted font-weight-bolder">Past Policy</span>
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
    <!-- Policy Table  -->
    <ng-container *ngIf="view === 'policy'" [ngTemplateOutlet]="policyListings"></ng-container>
    <!-- Policy Table  -->

    <!-- Rule Table  -->
    <ng-container *ngIf="view === 'rule'" [ngTemplateOutlet]="ruleListings"></ng-container>
    <!-- Rule Table  -->

    <!-- <ng-container *ngIf="!isLoading && view === 'rule'"
                  [ngTemplateOutlet]="ruleTabListings"></ng-container>

    <ng-container *ngIf="!isLoading && view === 'pastRule'"
                  [ngTemplateOutlet]="pastRuleDetails"></ng-container> -->
</div>

<!-- skeletons  -->
<ng-template #skeletonTable>
    <div class="card card-margin mt-3">
        <div class="table-responsive styled-table">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
                        <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
                        <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
                        <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
                        <th scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
                        <th *ngIf="view !== 'policy'" scope="col"><ngx-skeleton-loader></ngx-skeleton-loader></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of [].constructor(4); let i = index">
                        <td><ngx-skeleton-loader></ngx-skeleton-loader></td>
                        <td><ngx-skeleton-loader></ngx-skeleton-loader></td>
                        <td><ngx-skeleton-loader></ngx-skeleton-loader></td>
                        <td *ngIf="view === 'policy'"><ngx-skeleton-loader></ngx-skeleton-loader></td>
                        <td *ngIf="view !== 'policy'" class="text-center" width="300">
                            <ngx-skeleton-loader count="5"
                                                 appearance="circle"></ngx-skeleton-loader>
                        </td>
                        <td><ngx-skeleton-loader></ngx-skeleton-loader></td>
                        <td *ngIf="view !== 'policy'"><ngx-skeleton-loader></ngx-skeleton-loader></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>
<!-- skeletons  -->

<!-- Policy Table  -->
<ng-template #policyListings>
    <div class="notice-container" *ngIf="buildType !== 'whitelabel'">
        <img class="note-bg" src="/assets/images/firewall-img.svg" />
        <p class="note-text">Secure your transaction using your Firewall Policies.</p>
        <a class="btn btn-primary btn-round" [href]="DOC_LINK" target="_blank">Learn More</a>
    </div>
    <div class="center card card-margin">
        <div class="d-flex p-4">
            <h2 class="mr-1 mb-0 font-weight-500 table-title">
                <i class="icon-shield mr-1"></i>
                Firewall Policies
            </h2>
        </div>
        <ng-container *ngIf="isLoading" [ngTemplateOutlet]="skeletonTable"></ng-container>
        <div class="row" *ngIf="!isLoading">
            <div class="col-sm-12">
                <div class="table-responsive table-new">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="pl-4">Policy</th>
                                <th>
                                    <div class="d-flex justify-content-center">Rules</div>
                                </th>
                                <th>
                                    <div class="d-flex justify-content-center">Status</div>
                                </th>
                                <th scope="col" class="text-left">
                                    Last Updated 
                                    <img class="ml-1" placement="top" ngbTooltip="Policy last updated date" src='/assets/images/info-ic.png'/>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of policiesList">
                                <td class="pl-4">
                                   <div class="policy-name-container">
                                        <div class="policy-icon-container">
                                            <img [src]="'/assets/images/policies/' + (item.ruleType.name | lowercase) + '.svg'" alt="" class="policy-icon">
                                        </div>
                                        <div>
                                            <p class="policy-name">
                                                {{generateNameFromEnum(item.ruleType.name, IRuleTypeTitle)}}
                                                <ng-container *ngFor="let chipItem of chipMap[item.ruleType.name]">
                                                    <ng-container *ngTemplateOutlet="chip; context:{type:chipItem}"></ng-container>
                                                </ng-container>
                                            </p>
                                            <span class="policy-desc">{{IRuleTypeDescription[item.ruleType.name]}}</span>
                                        </div>
                                   </div>
                                </td>
                                <td class="w-15">
                                    <div class="d-flex justify-content-center">
                                        <div class="rules-count">
                                            <span>{{((item.rules || []) | policyFilter:'receive' )?.length || '-'}}</span>
                                        </div>
                                    </div>
                                </td>
                                <td class="w-15">
                                    <div class="d-flex justify-content-center">
                                        <div *ngIf="item.status && item.firewall?.status; else firewallDisabled" ngbTooltip="Active" class="enable_dot d-inline-block align-items-center"></div>
                                        <ng-template #firewallDisabled >
                                            <div ngbTooltip="Inactive" class="archived_dot d-inline-block align-items-center"></div>
                                        </ng-template>
                                    </div>
                                </td>
                                <td class="update-time w-15">
                                   <ng-container *ngIf="item.updatedAt">
                                        <div>{{item.updatedAt | dateWithZone:'DD MMM YY, HH:mm' }}</div>
                                        <div>{{item.updatedAt | dateAgo }}</div>
                                   </ng-container>
                                   <ng-container *ngIf="!item.updatedAt">
                                    <div class="time">-</div>
                               </ng-container>
                                </td>
                                <td class="w-15">
                                    <div class="d-flex justify-content-end">
                                        <button class="btn view-btn btn-sm" [ngClass]="{disabled: item.status ? false : true}" (click)="onPolicyView(item)" [disabled]="!item.status">
                                            <i class="icon-eye"></i>
                                            <span>View</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Policy Table  -->


<!-- Rules Table  -->
<ng-template #ruleListings>
    <div class="editrule-notice" *ngIf="isEditMode">
        <div class="d-flex align-items-center">
            <img class="editrule-note-icon"
                 src="/assets/images/edit-rule.svg" />
            <div class="ml-4">
                <p class="editrule-note-text"><b>Edit Ruleset</b></p>
                <p class="editrule-note-text">Create sequential rule sets that execute from the first row to the last.
                    for any modification requires an approval.</p>
            </div>
        </div>
        <div class="d-flex">
            <button class="btn btn-outline-primary btn-round mr-2"
                    disabled>Cancel</button>
            <button class="btn btn-primary btn-round mr-2"
                    disabled>Submit</button>
        </div>
    </div>
    <div class="notice-container" *ngIf="!isRuleAppEnabled">
        <div class="notice-img">
            <img class="note-bg"src="/assets/images/policy-bg.svg" />
            <img class="note-icon" src="/assets/images/policies/{{selectedPolicy.ruleType.name | lowercase}}.svg" />
        </div>
        <p class="note-text">Your {{IRuleTypeTitle[selectedPolicy.ruleType.name]}} app is not active yet.</p>
        <a class="btn btn-primary btn-round" (click)="onEnableApp()">Learn More</a>
    </div>
    <div  *ngIf="selectedPolicy" class="center card card-margin">
        <!-- DONT REMOVE -->
        <!-- <div class="d-flex p-4 justify-content-between align-items-center ">
            <ng-container *ngIf="!isEditMode">
                <div>
                    <h2 class="mr-2 mb-2 font-weight-500">{{generateNameFromEnum(selectedPolicy.ruleType.name, IRuleTypeTitle)}}</h2>
                    <div class="send-receive-tabs">
                        <button class="tab-btn" [ngClass]="currentDirection === 'send' ? 'active' : ''" (click)="onDirectionChange('send')">Send</button>
                        <button class="tab-btn" [ngClass]="currentDirection === 'receive' ? 'active' : ''" (click)="onDirectionChange('receive')">Receive</button>
                    </div>
                </div>
                <button class="btn btn-primary"
                        (click)="onEditPolicyRules()">Edit Policy</button>
            </ng-container>
            <ng-container *ngIf="isEditMode">
                <h2 class="mr-2 mb-0 font-weight-500">Rules</h2>
                <div class="d-flex">
                    <button class="btn btn-outline-primary mr-2"
                            (click)="onEditPolicyRules()"
                            disabled>Clear all Rules</button>
                    <button class="btn btn-primary"
                            (click)="onEditPolicyRules()"
                            disabled>+ Add New Rule</button>
                </div>
            </ng-container>
        </div> -->
        <ng-container *ngIf="isLoading" [ngTemplateOutlet]="skeletonTable"></ng-container>
        <ng-container *ngIf="!isLoading">
            <ng-container  *ngTemplateOutlet="ruleTable; context:{list:filteredRulesList}"></ng-container>
        </ng-container>
    </div>
</ng-template>
<!-- Rules Table  -->

<!-- TODO - Move transfer policy to it's own component -->
<ng-template #ruleTable let-list="list">
    <div class="row">
        <div class="col-sm-12">
            <div class="table-responsive table-new">
                <table class="table rule-table">
                    <thead>
                        <tr>
                            <th width="5%" class="pl-4">Priority</th>
                            <th width="15%" *ngIf="isTransferPolicy" >
                                <div class="d-flex justify-content-center">Source</div>
                            </th>
                            <th width="15%" *ngIf="isTransferPolicy" >
                                <div class="d-flex justify-content-center">Destination</div>
                            </th>
                            <th width="20%">
                                <div class="d-flex justify-content-center">Protocol</div>
                            </th>
                            <th width="20%">
                                <div class="d-flex justify-content-center">Asset</div>
                            </th>
                            <th width="15%" *ngIf="isTransferPolicy">
                                <div class="d-flex justify-content-center">Min Amount</div>
                            </th>
                            <th width="30%" *ngIf="!isTransferPolicy">
                                <div class="d-flex">
                                    <ng-container *ngIf="selectedPolicy.ruleType.name === IRuleTypeCode.TRAVEL_RULE">
                                        Status
                                    </ng-container>
                                    <ng-container
                                                  *ngIf="selectedPolicy.ruleType.name === IRuleTypeCode.TRANSACTION_RISK">
                                        Risk Factor
                                    </ng-container>
                                </div>
                            </th>
                            <th width="20%">
                                <div class="d-flex justify-content-start">Outcome</div>
                            </th>
                            <th *ngIf="isEditMode"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of list | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize; let i = index">
                            <td class="pl-4">{{((page - 1) * pageSize) + (i + 1)}}</td>
                            <td *ngIf="isTransferPolicy">
                                <div class="d-flex justify-content-center text-center"><span class="origin-destination">{{
                                        getSourceDestinationDetails('origin',item) | titlecase }}</span>
                                </div>
                            </td>
                            <td *ngIf="isTransferPolicy">
                                <div class="d-flex justify-content-center text-center"><span class="origin-destination">{{
                                        getSourceDestinationDetails('destination',item) | titlecase }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    <span class="protocol-item">{{item.chain | getName}}</span>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    <app-asset-listing [assetsList]="item.asset || 'any'"></app-asset-listing>
                                </div>
                            </td> 
                            <td *ngIf="isTransferPolicy">
                                <div class="d-flex justify-content-center">{{deriveAmount(item)}}</div>
                            </td>
                            <td *ngIf="!isTransferPolicy">
                                <div class="d-flex status-col" >
                                    <ng-container>
                                        <ng-container *ngFor="let exp of (item?.rulesExpectedValues[0]?.value | splitstring:',')">
                                            <div [ngClass]="'status-item status-' + (exp | lowercase) + ' ' + (selectedPolicy.ruleType.name | lowercase)">
                                                <span *ngIf="selectedPolicy.ruleType.name === IRuleTypeCode.TRANSACTION_RISK">{{exp}}</span>
                                                <span *ngIf="selectedPolicy.ruleType.name === IRuleTypeCode.TRAVEL_RULE ">{{iTravelRuleStatus[exp | uppercase] || (exp | titlecase)}}</span>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-start ">
                                    <div class="outcome-col">
                                        <span [ngClass]="generateNameFromEnum(item.outcome, IRuleOutcome, true) | lowercase">{{generateNameFromEnum(item.outcome, IRuleOutcome, true)}}</span>
                                        <div *ngIf="item.teamConfig"> <p class="text-muted font-size-12 team-config">{{item.teamConfig}}</p></div>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="isEditMode">
                                <div ngbDropdown
                                     container="body">
                                    <button class="dropDown-btn"
                                            ngbDropdownToggle>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="16"
                                             height="16"
                                             fill="currentColor"
                                             class="bi bi-three-dots-vertical"
                                             viewBox="0 0 16 16">
                                            <path
                                                  d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg>
                                        <div ngbDropdownMenu
                                             style="border-radius: 12px">
                                            <button ngbDropdownItem
                                                    disabled
                                                    class="dropdown-item">
                                                Add Above
                                            </button>
                                            <button ngbDropdownItem
                                                    disabled
                                                    class="dropdown-item">
                                                Add Below
                                            </button>
                                            <button ngbDropdownItem
                                                    disabled
                                                    class="dropdown-item">
                                                Edit
                                            </button>
                                            <button ngbDropdownItem
                                                    disabled
                                                    class="dropdown-item">
                                                Delete
                                            </button>
                                        </div>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="
                list.length > 0"
                 class="col-12 my-3">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="table-footer-text"> Showing <span>{{ list.length | paginationFromTo : page : pageSize }}</span> of {{ list.length }} entries </span>
                    <div class="pagination">
                        <ngb-pagination [collectionSize]="list.length"
                                        [(page)]="page"
                                        [pageSize]="pageSize"
                                        [maxSize]="3"
                                        [boundaryLinks]="true"
                                        [ellipses]="false">
                            <ng-template class="left_arrow"
                                         ngbPaginationFirst>
                                <i class="icon-arrow-left"
                                   placement="top"
                                   ngbTooltip="First"></i>
                                <i class="icon-arrow-left"
                                   placement="top"
                                   ngbTooltip="First"></i>
                            </ng-template>
                            <ng-template class="left_arrow"
                                         ngbPaginationPrevious>
                                <i class="icon-arrow-left"
                                   placement="top"
                                   ngbTooltip="Previous"></i>
                            </ng-template>
                            <ng-template class="right_arrow"
                                         ngbPaginationNext>
                                <i class="icon-arrow-right"
                                   placement="top"
                                   ngbTooltip="Next"></i>
                            </ng-template>
                            <ng-template class="right_arrow"
                                         ngbPaginationLast>
                                <i class="icon-arrow-right"
                                   placement="top"
                                   ngbTooltip="Last"></i>
                                <i class="icon-arrow-right"></i>
                            </ng-template>
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #chip let-type="type">
    <div [ngClass]="'chip chip-' + type">
        <span *ngIf="type === 'new'">New</span>
        <span *ngIf="type === 'premium'">Premium</span>
        <span *ngIf="type === 'coming'">Coming Soon</span>
    </div>
</ng-template>
