"use strict";

var CONSTANTS = _interopRequireWildcard(require("../constants"));
var P = _interopRequireWildcard(require("./params"));
var Device = _interopRequireWildcard(require("./trezor/device"));
var Mgmnt = _interopRequireWildcard(require("./trezor/management"));
var Protobuf = _interopRequireWildcard(require("./trezor/protobuf"));
var Account = _interopRequireWildcard(require("./account"));
var Bitcoin = _interopRequireWildcard(require("./networks/bitcoin"));
var Binance = _interopRequireWildcard(require("./networks/binance"));
var Cardano = _interopRequireWildcard(require("./networks/cardano"));
var CoinInfo = _interopRequireWildcard(require("./networks/coinInfo"));
var EOS = _interopRequireWildcard(require("./networks/eos"));
var Ethereum = _interopRequireWildcard(require("./networks/ethereum"));
var NEM = _interopRequireWildcard(require("./networks/nem"));
var Ripple = _interopRequireWildcard(require("./networks/ripple"));
var Stellar = _interopRequireWildcard(require("./networks/stellar"));
var Tezos = _interopRequireWildcard(require("./networks/tezos"));
var Misc = _interopRequireWildcard(require("./misc"));
var Events = _interopRequireWildcard(require("./events"));
var Blockchain = _interopRequireWildcard(require("./backend/blockchain"));
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj["default"] = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}