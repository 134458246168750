export function delay(delayMs, delayCleanup) {
  var timerId;
  var promise = new Promise(function (resolve) {
    return timerId = setTimeout(resolve, delayMs);
  });
  if (delayCleanup) {
    delayCleanup.clearTimer = function () {
      return clearTimeout(timerId);
    };
  }
  return promise;
}
export function errorToString(err, includeStackTrace) {
  if (includeStackTrace === void 0) {
    includeStackTrace = false;
  }
  return err instanceof Error ? includeStackTrace && err.stack ? err.stack : err.toString() : err + "";
}
export function throwError(err) {
  throw err;
}
export function isArray(value) {
  // See also: https://github.com/microsoft/TypeScript/issues/17002#issuecomment-1477626624
  return Array.isArray(value);
}
export function isStringArray(value) {
  return isArray(value) && !value.some(function (item) {
    return typeof item !== "string";
  });
}
export function formatStringList(items, maxLength, getOmittedItemsText, separator) {
  if (maxLength === void 0) {
    maxLength = 0;
  }
  if (separator === void 0) {
    separator = ", ";
  }
  var length = items.length;
  if (!length) {
    return "";
  }
  var appendix = "";
  if (maxLength > 0 && length > maxLength) {
    items = items.slice(0, maxLength);
    if (getOmittedItemsText) {
      appendix = getOmittedItemsText(length - maxLength);
    }
  }
  return "'" + items.join("'" + separator + "'") + "'" + appendix;
}
export function isPromiseLike(obj) {
  var _a;
  // See also: https://stackoverflow.com/a/27746324/8656352
  return typeof ((_a = obj) === null || _a === void 0 ? void 0 : _a.then) === "function";
}
export function utf8Encode(text) {
  function codePointAt(text, index) {
    var ch = text.charCodeAt(index);
    if (0xD800 <= ch && ch < 0xDC00) {
      // is high surrogate?
      var nextCh = text.charCodeAt(index + 1);
      if (0xDC00 <= nextCh && nextCh <= 0xDFFF) {
        // is low surrogate?
        return (ch << 10) + nextCh - 0x35FDC00;
      }
    }
    return ch;
  }
  var utf8text = "",
    chunkStart = 0;
  var fromCharCode = String.fromCharCode;
  var i;
  for (i = 0; i < text.length; i++) {
    var cp = codePointAt(text, i);
    if (cp <= 0x7F) {
      continue;
    }
    // See also: https://stackoverflow.com/a/6240184/8656352
    utf8text += text.slice(chunkStart, i);
    if (cp <= 0x7FF) {
      utf8text += fromCharCode(0xC0 | cp >> 6);
      utf8text += fromCharCode(0x80 | cp & 0x3F);
    } else if (cp <= 0xFFFF) {
      utf8text += fromCharCode(0xE0 | cp >> 12);
      utf8text += fromCharCode(0x80 | cp >> 6 & 0x3F);
      utf8text += fromCharCode(0x80 | cp & 0x3F);
    } else {
      utf8text += fromCharCode(0xF0 | cp >> 18);
      utf8text += fromCharCode(0x80 | cp >> 12 & 0x3F);
      utf8text += fromCharCode(0x80 | cp >> 6 & 0x3F);
      utf8text += fromCharCode(0x80 | cp & 0x3F);
      ++i;
    }
    chunkStart = i + 1;
  }
  return utf8text += text.slice(chunkStart, i);
}
export function parseFloatStrict(value) {
  // NOTE: JS's float to string conversion is too forgiving, it accepts hex numbers and ignores invalid characters after the number.
  if (typeof value === "number") {
    return value;
  }
  if (typeof value !== "string" || !value.length || /^\s*$|^\s*0[^\d.e]/.test(value)) {
    return NaN;
  }
  return +value;
}