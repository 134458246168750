import { __extends, __spreadArrays } from "tslib";
import { ExternalConfigCache, InMemoryConfigCache } from "./ConfigCatCache";
import { ConfigCatConsoleLogger, LoggerWrapper } from "./ConfigCatLogger";
import { DefaultEventEmitter } from "./DefaultEventEmitter";
import { NullEventEmitter } from "./EventEmitter";
import { sha1 } from "./Hash";
import { Hooks } from "./Hooks";
import { getWeakRefStub, isWeakRefAvailable } from "./Polyfills";
import { ProjectConfig } from "./ProjectConfig";
/** Specifies the supported polling modes. */
export var PollingMode;
(function (PollingMode) {
  /** The ConfigCat SDK downloads the latest values automatically and stores them in the local cache. */
  PollingMode[PollingMode["AutoPoll"] = 0] = "AutoPoll";
  /** The ConfigCat SDK downloads the latest setting values only if they are not present in the local cache, or if the cache entry has expired. */
  PollingMode[PollingMode["LazyLoad"] = 1] = "LazyLoad";
  /** The ConfigCat SDK will not download the config JSON automatically. You need to update the cache manually, by calling `forceRefresh()`. */
  PollingMode[PollingMode["ManualPoll"] = 2] = "ManualPoll";
})(PollingMode || (PollingMode = {}));
/** Controls the location of the config JSON files containing your feature flags and settings within the ConfigCat CDN. */
export var DataGovernance;
(function (DataGovernance) {
  /** Choose this option if your config JSON files are published to all global CDN nodes. */
  DataGovernance[DataGovernance["Global"] = 0] = "Global";
  /** Choose this option if your config JSON files are published to CDN nodes only in the EU. */
  DataGovernance[DataGovernance["EuOnly"] = 1] = "EuOnly";
})(DataGovernance || (DataGovernance = {}));
/* eslint-disable @typescript-eslint/no-inferrable-types */
var OptionsBase = /** @class */function () {
  function OptionsBase(sdkKey, clientVersion, options, defaultCacheFactory, eventEmitterFactory) {
    var _a, _b, _c;
    this.requestTimeoutMs = 30000;
    this.baseUrlOverriden = false;
    this.proxy = "";
    this.offline = false;
    if (!sdkKey) {
      throw new Error("Invalid 'sdkKey' value");
    }
    this.sdkKey = sdkKey;
    this.clientVersion = clientVersion;
    this.dataGovernance = (_a = options === null || options === void 0 ? void 0 : options.dataGovernance) !== null && _a !== void 0 ? _a : DataGovernance.Global;
    switch (this.dataGovernance) {
      case DataGovernance.EuOnly:
        this.baseUrl = "https://cdn-eu.configcat.com";
        break;
      default:
        this.baseUrl = "https://cdn-global.configcat.com";
        break;
    }
    var eventEmitter = (_b = eventEmitterFactory === null || eventEmitterFactory === void 0 ? void 0 : eventEmitterFactory()) !== null && _b !== void 0 ? _b : new DefaultEventEmitter();
    var hooks = new Hooks(eventEmitter);
    var hooksWeakRef = new (isWeakRefAvailable() ? WeakRef : getWeakRefStub())(hooks);
    this.hooks = {
      hooks: hooks,
      hooksWeakRef: hooksWeakRef,
      emit: function (eventName) {
        var _a, _b;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
          args[_i - 1] = arguments[_i];
        }
        return (_b = (_a = this.hooksWeakRef.deref()) === null || _a === void 0 ? void 0 : _a.emit.apply(_a, __spreadArrays([eventName], args))) !== null && _b !== void 0 ? _b : false;
      }
    };
    var logger;
    var cache;
    if (options) {
      logger = options.logger;
      cache = options.cache;
      if (options.requestTimeoutMs) {
        if (options.requestTimeoutMs < 0) {
          throw new Error("Invalid 'requestTimeoutMs' value");
        }
        this.requestTimeoutMs = options.requestTimeoutMs;
      }
      if (options.baseUrl) {
        this.baseUrl = options.baseUrl;
        this.baseUrlOverriden = true;
      }
      if (options.proxy) {
        this.proxy = options.proxy;
      }
      if (options.flagOverrides) {
        this.flagOverrides = options.flagOverrides;
      }
      if (options.defaultUser) {
        this.defaultUser = options.defaultUser;
      }
      if (options.offline) {
        this.offline = options.offline;
      }
      (_c = options.setupHooks) === null || _c === void 0 ? void 0 : _c.call(options, hooks);
    }
    this.logger = new LoggerWrapper(logger !== null && logger !== void 0 ? logger : new ConfigCatConsoleLogger(), this.hooks);
    this.cache = cache ? new ExternalConfigCache(cache, this.logger) : defaultCacheFactory ? defaultCacheFactory(this) : new InMemoryConfigCache();
  }
  OptionsBase.prototype.yieldHooks = function () {
    var hooksWrapper = this.hooks;
    var hooks = hooksWrapper.hooks;
    delete hooksWrapper.hooks;
    return hooks !== null && hooks !== void 0 ? hooks : new Hooks(new NullEventEmitter());
  };
  OptionsBase.prototype.getUrl = function () {
    return this.baseUrl + "/configuration-files/" + this.sdkKey + "/" + OptionsBase.configFileName + "?sdk=" + this.clientVersion;
  };
  OptionsBase.prototype.getCacheKey = function () {
    return sha1(this.sdkKey + "_" + OptionsBase.configFileName + "_" + ProjectConfig.serializationFormatVersion);
  };
  OptionsBase.configFileName = "config_v6.json";
  return OptionsBase;
}();
export { OptionsBase };
var AutoPollOptions = /** @class */function (_super) {
  __extends(AutoPollOptions, _super);
  function AutoPollOptions(sdkKey, sdkType, sdkVersion, options, defaultCacheFactory, eventEmitterFactory) {
    var _this = _super.call(this, sdkKey, sdkType + "/a-" + sdkVersion, options, defaultCacheFactory, eventEmitterFactory) || this;
    _this.pollIntervalSeconds = 60;
    _this.maxInitWaitTimeSeconds = 5;
    if (options) {
      if (options.pollIntervalSeconds != null) {
        _this.pollIntervalSeconds = options.pollIntervalSeconds;
      }
      if (options.maxInitWaitTimeSeconds != null) {
        _this.maxInitWaitTimeSeconds = options.maxInitWaitTimeSeconds;
      }
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
    // https://stackoverflow.com/a/3468650/8656352
    var maxSetTimeoutIntervalSecs = 2147483;
    if (!(typeof _this.pollIntervalSeconds === "number" && 1 <= _this.pollIntervalSeconds && _this.pollIntervalSeconds <= maxSetTimeoutIntervalSecs)) {
      throw new Error("Invalid 'pollIntervalSeconds' value");
    }
    if (!(typeof _this.maxInitWaitTimeSeconds === "number" && _this.maxInitWaitTimeSeconds <= maxSetTimeoutIntervalSecs)) {
      throw new Error("Invalid 'maxInitWaitTimeSeconds' value");
    }
    return _this;
  }
  return AutoPollOptions;
}(OptionsBase);
export { AutoPollOptions };
var ManualPollOptions = /** @class */function (_super) {
  __extends(ManualPollOptions, _super);
  function ManualPollOptions(sdkKey, sdkType, sdkVersion, options, defaultCacheFactory, eventEmitterFactory) {
    return _super.call(this, sdkKey, sdkType + "/m-" + sdkVersion, options, defaultCacheFactory, eventEmitterFactory) || this;
  }
  return ManualPollOptions;
}(OptionsBase);
export { ManualPollOptions };
var LazyLoadOptions = /** @class */function (_super) {
  __extends(LazyLoadOptions, _super);
  function LazyLoadOptions(sdkKey, sdkType, sdkVersion, options, defaultCacheFactory, eventEmitterFactory) {
    var _this = _super.call(this, sdkKey, sdkType + "/l-" + sdkVersion, options, defaultCacheFactory, eventEmitterFactory) || this;
    _this.cacheTimeToLiveSeconds = 60;
    if (options) {
      if (options.cacheTimeToLiveSeconds != null) {
        _this.cacheTimeToLiveSeconds = options.cacheTimeToLiveSeconds;
      }
    }
    if (!(typeof _this.cacheTimeToLiveSeconds === "number" && 1 <= _this.cacheTimeToLiveSeconds && _this.cacheTimeToLiveSeconds <= 2147483647)) {
      throw new Error("Invalid 'cacheTimeToLiveSeconds' value");
    }
    return _this;
  }
  return LazyLoadOptions;
}(OptionsBase);
export { LazyLoadOptions };