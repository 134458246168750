import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { HttpService } from '../../services/http.service';
import { csvGeneration } from '../../helpers/general';
import { SegmentService } from 'ngx-segment-analytics';
import { CustomPrompt, ErrorPrompt, SuccessPrompt } from '../custom-prompt/custom-prompt.service';
import { APIKeyDownMenu, PromptMessages } from '../../constants/apiKeys.const';
import { ApiKeyService } from '../../services/api-key.service';
import { UserStatusEnum } from '@entities/User';
import { Router } from '@angular/router';
import { UserRoleEnum } from '../../entities/members';
import { AuthServiceJWT } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { scopes } from '../../constants/authScopes';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-generate-api-key',
  templateUrl: './generate-api-key.component.html',
  styleUrls: ['./generate-api-key.component.scss'],
})
export class GenerateApiKeyComponent implements OnInit {
  @ViewChild('generate_key') gen_key: any;
  @ViewChild('rotateKey') rotateKey: any;
  @ViewChild('dp') datePicker: NgbInputDatepicker;
  apiData: any;
  apiDetails: any;
  alertClosed: boolean = false;
  @Input() userType: UserRoleEnum;
  @Input() component_for: string;
  @Input() sdkEnabled: boolean;
  webhook_Url: string = '';
  webhook;
  stage = 'webhook_url';
  submitted = false;
  verifyHook;
  step: string = 'step-1';
  isLoading = false;
  userName: string;
  userEmail: string;
  keyExpiration: NgbDateStruct | Date;
  dropdownMenus = APIKeyDownMenu;
  promptMessages = PromptMessages;
  showDate = false;
  today: NgbDate;
  maxRange: any;
  validAPIkeys: any;
  openIpAddressRestrictModal = 0;
  currentDetails;
  UserStatus = UserStatusEnum;
  userRole = UserRoleEnum;
  buildName = environment.buildName;

  constructor(
    private segment: SegmentService,
    private modalService: NgbModal,
    private httpservice: HttpService,
    private apiKeyService: ApiKeyService,
    private router: Router,
    private calendar: NgbCalendar,
    private authService: AuthServiceJWT,
    private apiService: ApiService
  ) {
    this.getAPIDetails();
    this.getWebhook();
  }

  ngOnInit(): void {
    const { minRange, maxRange } = this.apiKeyService.setMaxDateRange();
    this.today = minRange;
    this.maxRange = maxRange;
  }

  async getWebhook() {
    let response = await this.httpservice.getWebhook().toPromise();
    if (response.success) {
      this.webhook = response.data;
    }
  }

  async getAPIDetails() {
    this.apiDetails = await this.httpservice.view_api_details().toPromise();
    this.validAPIkeys = this.apiDetails.data.filter(
      (key) => key.status != this.UserStatus.API_KEY_DELETED_OR_DISABLED
    );
    if (this.validAPIkeys.length) {
      this.dropdownMenus[2].disabled =
        this.validAPIkeys[0].status == this.UserStatus.ACTIVE ? false : true;
      this.dropdownMenus[3].disabled =
        this.validAPIkeys[0].status == this.UserStatus.ACTIVE ? false : true;
    }
    this.apiDetails.data.forEach((element) => {
      this.checkKeyExpired(element);
      if (element.providers) {
        let providersList = [];
        providersList = element.providers.map((e) => {
          return e.toLowerCase();
        });
        const mobile = providersList.indexOf('mobile_mpc');
        const mobile_pending = providersList.indexOf('ios_mpc');
        if (mobile !== -1) {
          element.providers[mobile] = 'mpc';
        }
        if (mobile_pending !== -1) {
          element.providers[mobile_pending] = 'mpc setup pending';
        }
        if (mobile !== -1 && mobile_pending !== -1) {
          element.providers.splice(mobile_pending, 1);
        }
      }
    });
    console.log('this.apiDetails', this.apiDetails);
  }

  async generate_Key(name, email, keyExpiration) {
    this.isLoading = true;
    let dataobj = {
      name: name,
      email: email,
      keyExpiration: `${keyExpiration.year}/${keyExpiration.month}/${keyExpiration.day}`,
    };
    try {
      this.apiData = await this.apiService.executeApiWithAuth2Fa(scopes.ApiKeyCreate, (accessToken) =>
        this.httpservice.generate_api_key(dataobj, accessToken)
      );
      console.log('gen api key', this.apiData);
      if (this.apiData.success === true) {
        this.segment.track('api-key-generated', dataobj).catch((err) => {});
        this.getAPIDetails();
        this.isLoading = false;
        this.step = 'step-2';
        this.apiData.data.userName = name;
        this.apiData.data.userEmail = email;
      } else {
        this.modalService.dismissAll();
        ErrorPrompt.fire({
          icon: 'error',
          title: 'Failed to generate keys',
          text: this.apiData.message,
          showConfirmButton: false,
        });
      }
    } catch (e) {
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: 'error',
        title: 'Failed to generate API key',
        text: e.error.message,
        showConfirmButton: false,
      });
      console.error(e);
    }
  }

  downloadAPI() {
    csvGeneration(
      [
        {
          Name: this.apiData.data.userName,
          Email: this.apiData.data.userEmail,
          Client_ID: this.apiData.data.clientId,
          Client_Secret: this.apiData.data.clientSecret,
          Role: 'Signer',
        },
      ],
      ['Name', 'Email', 'Client ID', 'Client Secret', 'Role'],
      'Liminal_API_Key'
    );
  }

  async update_Webhook() {
    let data;
    this.submitted = true;
    data = await this.httpservice.webhook_url(this.webhook_Url).toPromise();
    if (data.success == true) {
      this.segment.track('webhook-configured').catch((err) => {});
      this.stage = 'success';
      this.getWebhook();
    }
  }

  close() {
    if (!this.alertClosed) {
      Swal.fire({
        icon: 'info',
        title: 'ALERT',
        text: 'API key can be viewed only once. Please store it securely!',
        showCancelButton: true,
        confirmButtonText: "Wait! I'll save.",
        cancelButtonText: 'No need',
      }).then((result) => {
        if (result.isDismissed) {
          this.alertClosed = true;
          this.modalService.dismissAll();
          this.getAPIDetails();
        }
      });
    } else {
      this.modalService.dismissAll();
    }
  }

  webhook_close() {
    this.stage = 'webhook_url';
    this.modalService.dismissAll();
  }

  open_webhook(content) {
    this.stage = 'webhook_url';
    this.webhook_Url = '';
    this.modalService.open(content, { windowClass: ' modal-custom-background', centered: true });
  }

  open(content) {
    this.modalService.open(content, { windowClass: ' modal-custom-background', centered: true });
    this.getAPIDetails();
  }

  generate_key_open() {
    if (!this.sdkEnabled) {
      return;
    }
    this.step = 'step-1';
    this.isLoading = false;
    this.userName = '';
    this.userEmail = '';
    this.keyExpiration = null;
    this.modalService.open(this.gen_key, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'new-group-wallet modal-custom-background',
      centered: true,
    });
  }

  restrictIpAddress(details = null) {
    this.currentDetails = details ? details : this.validAPIkeys[0];
    this.openIpAddressRestrictModal = Date.now();
  }

  async customPrompt(data, promptData, item) {
    let res = await CustomPrompt.fire(
      {
        callbackData: data,
        title: promptData.title,
        text: promptData.text,
        confirmButtonText: promptData.btnText,
        icon: promptData.icon,
      },
      (result) => {
        if (result.success) {
          this[item.callback](data);
          result && result.modalRef.close('closed');
        }
      }
    );
  }

  handleDropdownMenu(item, details) {
    switch (item.id) {
      case 1:
        const message =
          details.status == this.UserStatus.ACTIVE
            ? PromptMessages.DISABLE_KEY
            : PromptMessages.ENABLE_KEY;
        this.customPrompt(details, message, item);
        break;
      case 2:
        this.customPrompt(details, PromptMessages.DELETE_KEY, item);
        break;
      case 3:
        this.restrictIpAddress(details);
        break;
      case 4:
        this.handleRotateKeys(details);
        break;
      default:
        break;
    }
  }

  checkKeyExpired(details) {
    if (details.keyExpiration) {
      let currDate = new Date();
      let expDate = new Date(details.keyExpiration);
      if (expDate.getTime() <= currDate.getTime()) details['expired'] = true;
      else details['expired'] = false;
    }
  }

  async updateKeyStatus(details) {
    this.modalService.dismissAll();
    this.authService.writeAccess(scopes.ApiKeyEnableDisable).subscribe(async (token) => {
      const payload = {
        keyUserId: details.id,
        status: details.status == this.UserStatus.ACTIVE ? 0 : 1,
      };
      const data = await this.apiKeyService.updateApiKeyStatus(payload, token).toPromise();
      if (data) {
        const successMessage =
          details.status == this.UserStatus.ACTIVE
            ? this.promptMessages.SUCCESS_DISABLE_KEY
            : this.promptMessages.SUCCESS_ENABLE_KEY;
        SuccessPrompt.fire({
          title: successMessage.title,
          text: successMessage.text,
        });
        this.getAPIDetails();
      }
    });
  }

  async deleteAPIKey(details) {
    this.modalService.dismissAll();
    this.authService.writeAccess(scopes.ApiKeyEnableDisable).subscribe(async (token) => {
      const data = await this.apiKeyService.deleteAPIkey(details.id, token).toPromise();
      if (data) {
        SuccessPrompt.fire({
          title: this.promptMessages.SUCCESS_DELETE_KEY.title,
          text: this.promptMessages.SUCCESS_DELETE_KEY.text,
        });
        this.getAPIDetails();
      }
    });
  }

  handleRotateKeys(details) {
    this.currentDetails = details;
    this.modalService.open(this.rotateKey, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'new-group-wallet modal-custom-background',
      centered: true,
    });
  }

  closeRotateKey(event) {
    if (event == 'step-1') {
      this.modalService.dismissAll();
    } else {
      this.alertClosed = false;
      this.close();
    }
  }

  closeDp() {
    this.datePicker.close();
  }

  goToWebhookHistory() {
    console.log('object is webhook');
    this.router.navigate(['/dev/webhook/history']);
  }

  handleDateChange(newDate: NgbDateStruct) {
    this.keyExpiration = newDate;
  }
}
