import { Component, OnDestroy, OnInit } from '@angular/core';
import { FirewallService } from './firewall.service'
import { Subscription } from 'rxjs';
import { Firewall, IFirewallPolicy, OriginDestinationTypeEnum, Rule, RuleAppName, RuleOutcome, RuleType, RuleTypeCode, RuleTypeDescription, RuleTypeTitle } from '../../shared/entities/firewall.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../shared/services/data.service';
import { getApprovalConfig, isOutComeApproval } from '../../shared/components/pending-transactions/pending-txn-info/firewall.utils';
import { TravelRuleTransactionStatus } from '../../shared/entities/travel-rule-status';
import { OrgDetails } from '../../shared/entities/walletRequest';
import { Router } from '@angular/router';
import moment from 'moment';
import { AccessControlService } from '../../shared/services/access-control.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-firewall',
  templateUrl: './firewall.component.html',
  styleUrls: ['./firewall.component.scss']
})
export class FirewallComponent implements OnInit, OnDestroy {

  DOC_LINK = 'https://support.liminalcustody.com/support/solutions/articles/1060000079490-introducing-liminal-firewall-to-elevate-digital-asset-security';
  buildType = environment.buildType;
  iTravelRuleStatus = TravelRuleTransactionStatus;
  isLoading: boolean = false;
  isEditMode: boolean = false;
  page: number = 1;
  pageSize: number = 10;
  view: 'policy' | 'rule' | 'pastRule' = 'policy'
  policiesList: IFirewallPolicy[] = [];
  rulesList: Rule[] = [];
  filteredRulesList: Rule[] = [];
  selectedPolicy: IFirewallPolicy;
  IRuleTypeTitle = RuleTypeTitle;
  IRuleTypeDescription = RuleTypeDescription;
  IRuleAppName = RuleAppName;
  IRuleOutcome = RuleOutcome;
  IRuleTypeCode = RuleTypeCode;
  activeTab: string = 'active';
  subscriber: Subscription;
  isInitComplete: boolean = false;
  currentDirection: 'send' | 'receive' = 'send';
  chipMap = {
    TRAVEL_RULE: ['new'],
    TRANSACTION_RISK: ['new'],
    TRANSACTION_ANOMOLY: ['premium', 'coming'],
    TRANSFER_POLICY: [ 'new'],
    USER_ACCOUNTS: ['coming'],
    COUNTRY_RISK: '',
    LIVENESS: '',
  }
  organization: OrgDetails;
  isRuleAppEnabled: boolean = false;

  TRANSFER_POLICY_SPENDING_LIMIT = "spendingLimitDuration";
  DURATION_DAY_IN_HOURS = 24


  constructor(
    private _firewallService: FirewallService,
    private modalService: NgbModal,
    private router: Router,
    private data: DataService,
    private accessControlService: AccessControlService
  ) { 
    this.subscriber = new Subscription();
  }
 
  ngOnInit(): void {
    const isCustodyOrg = this.accessControlService.getIsCustodyOrg();

    this.subscriber.add(this.data.getUserProfile.subscribe(async (user_detail: any) => {
      if (!user_detail) return;
      const org = user_detail.organizations[0];
      this.organization = org;
      if (!this.isInitComplete) {
        this._firewallService.fetchPolicyListing(org.id);
      }
      this.isInitComplete = true;
    }));

    this.subscriber.add(this._firewallService.policiesSubject.subscribe((data) => {
      const list = (data || []).reduce((acc, item) => {
        acc[item.ruleType.name] = item;
        return acc;
      }, {});
      // Show only Transfer Policy for Custody Org customers
      this.policiesList = Object.keys(this.IRuleTypeCode)
        .filter((el) => {
          return (
            el !== RuleTypeCode.LIVENESS &&
            (isCustodyOrg ? el === RuleTypeCode.TRANSFER_POLICY : true)
          );
        })
        .map((el) => {
          const _list = list[el];
          if (_list) {
            const latestRule = _list.rules[0];
            list[el].updatedAt = latestRule?.updatedAt;
            return list[el];
          } else {
            return {
              id: 0,
              createdAt: "",
              updatedAt: "",
              createdBy: 0,
              status: 0,
              version: "0",
              firewall: {} as Firewall,
              ruleType: {
                name: el,
              } as RuleType,
              rules: [] as Rule[],
            } as IFirewallPolicy;
          }
        });
    
    }));
    
    this.subscriber.add(this._firewallService.loadingSubject.subscribe((flag) => {
      this.isLoading = flag;
    }));
    
    this.subscriber.add(this._firewallService.ruleSubject.subscribe((data) => {
      if (data && Object.keys(data).length) {
        this.selectedPolicy = data;
        let rules = data.rules.sort((a, b) => a.priority - b.priority);
        rules = rules.map((rule: Rule) => {
          if (isOutComeApproval(rule.outcome)) {
            rule.teamConfig = getApprovalConfig(rule)
          }
          return rule;
        })
        this.rulesList = rules;
        this.onDirectionChange(this.currentDirection);
      } else {
        this.rulesList = [];
      }
    }));
   
  }

  async onPolicyView(policy: IFirewallPolicy) {
    await this._firewallService.fetchRuleListing(policy.id);
    this.rulesList = [];
    this.selectedPolicy = policy;
    this.view = 'rule';
    this.page = 1;
    this.currentDirection = 'send';
    if (this.selectedPolicy.ruleType.name === this.IRuleTypeCode.TRAVEL_RULE) {
      this.isRuleAppEnabled = this.organization.travelRule > 0;
    } else if (this.selectedPolicy.ruleType.name === this.IRuleTypeCode.TRANSACTION_RISK) {
      this.isRuleAppEnabled = this.organization.trmStatus > 0;
    } else if (this.selectedPolicy.ruleType.name === this.IRuleTypeCode.TRANSFER_POLICY) {
      this.isRuleAppEnabled = true;
    }
  }

  onEditPolicyRules() {
    this.isEditMode = true;
  }

  generateNameFromEnum(enumValue: string, enumType: any, byValue = false) {
    if (byValue) {
      for (const key in enumType) {
        if (Object.prototype.hasOwnProperty.call(enumType, key)) {
          const element = enumType[key];
          if (element === String(enumValue)) {
            return key;
          }
        }
      }
    }
    return enumType[String(enumValue)] !== undefined ? enumType[String(enumValue)] : enumValue;
  }

  onFirewallNavigate() {
    this.selectedPolicy = null;
    this.rulesList = [];
    this.view = 'policy';
    this.isEditMode = false
  }

  onChangeTab(tab: string) {
    this.activeTab = tab;
  }

  onRestorePolicy(template: HTMLElement) {
    this.modalService.open(template, { centered: true });
  }
  
  onDirectionChange(direction: 'send' | 'receive') {
    this.currentDirection = direction;
    this.page = 1;
    this.filteredRulesList = this.rulesList.filter((rule) => rule.direction === direction);
  }

  onEnableApp() {
    if (this.selectedPolicy.ruleType.name === this.IRuleTypeCode.TRAVEL_RULE) {
      this.router.navigate(['settings/travel-rule'])
    } else if (this.selectedPolicy.ruleType.name === this.IRuleTypeCode.TRANSACTION_RISK) {
      this.router.navigate(['settings'], {queryParams: { q: this.IRuleTypeCode.TRANSACTION_RISK }})
    }
  }

  get isTransferPolicy(): boolean{
    return this.selectedPolicy?.ruleType?.name === RuleTypeCode.TRANSFER_POLICY;
  }

  /**
 * Retrieves the source or destination details based on the type.
 * @param originDestinationType - The type of the origin or destination.
 * @param rule - The rule containing the strategies.
 * @returns The name or label of the source or destination based on the type.
 */
  getSourceDestinationDetails(originDestinationType: 'origin' | 'destination', rule: Rule): string | undefined {
    const type = this.getTypeByOriginDestination(originDestinationType, rule);
    const strategy = this.getStrategyByOriginDestination(originDestinationType, rule);

    if (!type || !strategy) {
      return undefined;
    }

    return this.getDetailsByType(type, strategy);
  }

  /**
  * Retrieves the type of the origin or destination.
  * @param originDestinationType - The type of the origin or destination.
  * @param rule - The rule containing the strategies.
  * @returns The type of the origin or destination.
  */
  private getTypeByOriginDestination(originDestinationType: 'origin' | 'destination', rule: Rule): OriginDestinationTypeEnum | undefined {
    return originDestinationType === 'origin' ? rule.originType?.type : rule.destinationType?.type;
  }

  /**
  * Retrieves the strategy of the origin or destination.
  * @param originDestinationType - The type of the origin or destination.
  * @param rule - The rule containing the strategies.
  * @returns The strategy of the origin or destination.
  */
  private getStrategyByOriginDestination(originDestinationType: 'origin' | 'destination', rule: Rule): any {
    return originDestinationType === 'origin' ? rule.originStrategy : rule.destinationStrategy;
  }

  /**
  * Retrieves the details based on the type and strategy.
  * @param type - The type of the origin or destination.
  * @param strategy - The strategy of the origin or destination.
  * @returns The name or label based on the type.
  */
  private getDetailsByType(type: OriginDestinationTypeEnum, strategy: any): string | undefined {
    switch (type) {
      case OriginDestinationTypeEnum.WALLET:
        return strategy.wallet?.name;
      case OriginDestinationTypeEnum.WALLET_GROUP:
        return strategy.walletGroup?.label;
      case OriginDestinationTypeEnum.ADDRESS_LIST:
        return "Whitelisted Addresses";
      case OriginDestinationTypeEnum.ANY:
        return "Any";
      default:
        return undefined;
    }
  }


  /**
   * Derives the amount for a given rule.
   * 
   * @param rule - The rule object.
   * @returns The derived amount as a string.
   */
  deriveAmount(rule: Rule) {
    const amount = parseFloat(Number(rule.amount).toFixed(8));

    // If the rule has a spending limit, return the spending limit
    if (rule.rulesExpectedValues) {
      const spendingLimit = rule.rulesExpectedValues.find(
        (rev) => rev.ruleKey === this.TRANSFER_POLICY_SPENDING_LIMIT
      );
      if (spendingLimit) {
        return `$${amount} in ${this.formatDuration(Number(spendingLimit.value))}`;
      }
    }

    // Transaction Limit
    return `$${amount} per transaction`;
  }


  /**
   * Converts the duration in hours to a human-readable string.
   * If the duration is greater than or equal to 24 hours, it will be converted to days.
   * Otherwise, it will be represented in hours.
   *
   * @param duration - The duration in hours.
   * @returns A human-readable string representation of the duration.
   */
  formatDuration(duration: number): string {
    let durationString: string;
    if (duration >= this.DURATION_DAY_IN_HOURS) {
      const days = Math.floor(moment.duration(duration, "hours").asDays());
      durationString = `${days} days`;
    } else {
      durationString = `${duration} hour(s)`;
    }
    return durationString;
  }
  

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

}
