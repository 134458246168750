<div class="modal-header">
    <h2 class="modal-title">
      <i class="icon-file-earmark-text mr-2"></i>
      Risk Disclosures
    </h2>
  </div>
  <div class="modal-body risk-dis">
    <h3>INTRODUCTION</h3>
    <p>
      The Risk Disclosure Statement provides the Client with information about the risks associated in Virtual Assets and Accepted Virtual Assets and Stablecoins listed on {{buildName}}. Clients are strongly advised to read this Risk Disclosure Statement carefully before deciding to use the platform.      
    </p>
    <p>
      The risks outlined in this statement are not exhaustive and only describe the general nature of the risks involved with holding Virtual Assets and Stablecoins. The intention of this statement is just to outline the risks, and not to discuss in detail all the risks associated with holding Virtual Assets and Stablecoins. Clients should undertake their own assessment as to the suitability of holding Virtual Assets or Stablecoins based on their own investigations, research and based on their experience, financial resources and objectives.
    </p>

    <h3>
      OVERVIEW OF GENERAL RISKS ASSOCIATED WITH VIRTUAL ASSETS
    </h3>
    <p>
      The Risk Disclosure Statement addresses the risks that are associated with transacting in Virtual Assets below:
      <li>
        (i) Assets are not Legal Tender;
      </li>
      <li>
        (ii) Loss of Value, Volatility and Uncertainty of Future performance;
      </li>
      <li>
        (iii) Market Forces;
      </li>
      <li>
        (iv) Financial Crime and Cyber Attacks;
      </li>
      <li>
        (v) Availability of Virtual Assets;
      </li>
      <li>
        (vi) Technology Risk;
      </li>
      <li>
        (vii) Regulatory Risk.
      </li>
    </p>

    <h3>
      DISCLOSURES<br>
      A. RISKS RELATED TO THE SERVICES, VIRTUAL ASSETS, AND ACCEPTED VIRTUAL ASSETS AND STABLECOINS
    </h3>
    <p>
      RISK OF LOSS IN TRADING VIRTUAL ASSETS CAN BE SUBSTANTIAL AND YOU SHOULD, THEREFORE, CAREFULLY CONSIDER WHETHER SUCH TRADING IS APPROPRIATE FOR YOU IN LIGHT OF YOUR CIRCUMSTANCES AND FINANCIAL RESOURCES. YOU SHOULD BE AWARE OF THE FOLLOWING:
    </p>
    <p class="font-weight-bold m-0">
      (i) Virtual Assets are Not Legal Tender
    </p>
    <p>
      Most Virtual Assets are not backed by any central government or legal tender (in the UAE), meaning each country has different standards.
      There is no assurance that a person who accepts a Virtual Asset as payment today will continue to do so in the future. Holders of virtual assets put their trust in a digital, decentralized and partially anonymous system that relies on peer-to-peer networking and cryptography to maintain its integrity, and neither vendors nor individuals have an obligation to accept Virtual assets as payment in the future;      
    </p>
    <p class="font-weight-bold m-0">
      (ii) Loss of Value, Volatility and Uncertainty of Future Performance
    </p>
    <p>
      There is limited or no fundamental reasoning behind the pricing of Virtual Assets, creating the risk of volatility and unpredictability in the price of Virtual Assets relative to Fiat Currencies. Virtual Assets have had historically higher price volatility than Fiat Currencies with no or limited tangible underlying for price reference, allowing irrational and exorbitant moves in price as the process for valuation is speculative and uncertain.
    </p>
    <p class="font-weight-bold m-0">
      (iii) Market Forces
    </p>
    <p>
      Trading in Virtual Assets may be susceptible to irrational market forces, such as speculative bubbles, manipulation, scams, and fraud.
    </p>
    <p class="font-weight-bold m-0">
      (iv) Financial Crime and Cyber Attacks
    </p>
    <p>
      Cyber Crime is more prevalent as the ecosystem is totally digital and devoid of traditional governance. The nature of Virtual Assets may lead to an increased risk of cyber-attack as the ecosystem is totally digital. For example, a 51% attack is an attack on a blockchain by any Person or group of Persons who control more than 50% of the network's mining hash rate. Attackers with majority control of the network can interrupt the recording of new blocks by preventing other miners from completing blocks, altering payment history and subverting funds.
      Clients are susceptible to Malware and fake/hijacked addresses and other forms of Cyber-attacks that holding Virtual Assets may pose and Clients should always take care of passwords and double check the addresses and URLs before loading software.
    </p>
    <p class="font-weight-bold m-0">
      (v) Availability of Virtual Assets
    </p>
    <p>
      Virtual Assets are required to be approved by the FSRA for holding on the Platform. Such approval may be withdrawn at any time. Any Virtual Asset may be delisted at any time without any notice or consent. Similarly, any new Virtual Asset arising from a hard fork or similar changes to a Virtual Asset's protocols will require approval by the FSRA prior to being available on the Platform.
    </p>
    <p class="font-weight-bold m-0">
      (vi) Technology Risk
    </p>
    <p>
      The risks of Virtual Assets being transacted via new technologies, (including distributed ledger technologies (‘DLT’) with regards to, among other things, anonymity, irreversibility of transactions, accidental transactions, transaction recording, and settlement.
      Transactions in Virtual Assets on the blockchain relies on the proper functioning of complex software, which exacerbates the risk of access to or use of Virtual Assets being impaired or prevented. Failing to acknowledge this can prevent Clients from use/access to Virtual Assets.      
    </p>
    <p class="font-weight-bold m-0">
      (vii) Regulatory Risk
    </p>
    <p>
      Many trading venues and Virtual Asset services are not regulated, or subject to limited regulation, and Clients should choose counterparties after careful due diligence.
      You further acknowledge the above list of risks is non-exhaustive and there may also be unpredictable risks. Except as described below, we are not responsible for any losses of any nature arising from your use of the Website.      
    </p>

    <h3>
      B. SPECIFIC RISKS ASSOCIATED WITH STABLECOINS
    </h3>
    <p class="m-0">
      {{buildName}} only acts as a custodian for Stablecoin activities, and in no case is an issuer, operator, manager nor by any means a beneficiary of a Stablecoin. Therefore, before conducting any transaction or trading in Stablecoins, you should review the project page for detailed information and be aware of any particular terms and conditions of the particular Stablecoin’s issuer.
    </p>
    <p>
      Stablecoins are designed to be pegged to Fiat Currency collateralized 1:1. However, Stablecoins are not subject to any deposit insurance protection scheme, and the presence of Fiat Currency reserves is not a guarantee for redemption. There is a possibility that the assets held in reserves are not sufficient or may not be available for redemption at times of extremely high demand. Volatility spikes in the market might lead to occasions where the price of a Stablecoin deviates from the underlying Fiat Currency.
    </p>
    <h3>
      (i) USDC SPECIFIC RISKS
    </h3>
    <p class="m-0">
      Launched in 2018, USD Coin (USDC) is one of top fiat backed stablecoin. USDC is a centralized token issued by Centre Consortium that was originally founded by the companies Coinbase and Circle Financial.
    </p>
    <p class="m-0">
      Each USDC is designed to be backed by USD collateral held in reserve by leading regulated US financial institutions. Circle is voluntary publishing financial reports on assets held in reserve, on a monthly basis.
    </p>
    <p class="m-0">
      However, USDC is not subject to any deposit insurance protection scheme, and the presence of USD reserve is not a guarantee for redemption. There is a possibility that the assets held in reserve are not sufficient or may not be available for redemption at times of extremely high demand.
    </p>
    <p class="m-0">
      As USDC is a token with a target of (price) stability which is said to be secured by assets denominated in USD or that are equivalent of USD, there is a risk that, as a result of controlling and regulatory rules to be imposed for such currency or assets, there may be unfavourable changes in use, transfer and redemption of USDC or tax liability may be imposed upon USDC holders.
    </p>
    <p class="m-0">
      Centre Consortium decides on countries where USDC transactions will be supported or materialized or eligibility and appropriateness of persons who are to perform transactions, there is a risk that the UAE may be removed from countries supported and/or transactions by certain users with USDC may be blocked. In such case, {{buildName}} may stop accepting, remove and/or close USDC for transactions.
    </p>
    <p></p>
    <p>
      For further information or support please contact Customer Support via email at support&#64;lmnl.app
    </p>
  </div>
  <div class="modal-footer border-0 rd-bottom">
    <div>
      <input class="checkbox_animated" type="checkbox" (change)="riskDisclosure($event)">
      <span class="text-dark font-size-12">do not show this message on next login</span>
    </div>
    <div>
      <button type="submit" class="btn btn-primary active" (click)="riskDisclosure(null, 'understand');modal.dismiss('Cross click');"> I Understand
      </button>  
    </div>
  </div>