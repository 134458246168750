import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { DataService } from "../../services/data.service";
import { HttpService } from "../../services/http.service";
import { WalletserviceService } from "../../services/walletservice.service";
import { toPretty } from "../../helpers/trezor/numberHelper";
import BN from "bignumber.js";
import { Wallet } from "../../entities/wallet";
import { LoggerService } from "../../services/logger.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Validators } from "@angular/forms";
import { EMPTY, Observable } from "rxjs";
import { FormControl } from "@angular/forms";
import { TransactionRequest, txType } from "../../entities/TransactionRequest";
import { ComposeTransaction } from "../../helpers/trezor/transactionComposer";
import { stakingSign } from "../../helpers/trezor/stakingSign";
import Swal from "sweetalert2";
import { Policy } from "src/app/shared/entities/Policy";
import { SegmentService } from "ngx-segment-analytics";
import { AuthServiceJWT } from "../../services/auth.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UAParser } from "ua-parser-js";
import { SendAssetsComponent } from "../send-assets/send-assets.component";
import { OrgFeatureName } from "@entities/OrganizationFeatures";
import { ErrorPrompt } from "../custom-prompt/custom-prompt.service";
import { ApiService } from "../../services/api.service";
import { scopes } from "../../constants/authScopes";
import { environment } from "src/environments/environment";

interface addressAmountPair {
  address: string;
  amount: number;
  policy: Policy;
}

@Component({
  selector: "app-staking-v2-popup",
  templateUrl: "./staking-v2-popup.component.html",
  styleUrls: ["./staking-v2-popup.component.scss"],
})
export class StakingV2PopupComponent implements OnInit {
  buildName = environment.buildName;
  closeResult = "";
  wallets: Wallet[];
  _wallet: any;
  // stakingData: any = [];
  searchWalletInput: string;
  searchAssetInput: string;
  filteredOptions: Observable<Wallet[]> = EMPTY;
  SendControl = new FormControl();
  ReceiveControl = new FormControl();
  walletList: Wallet[] = [];
  txRequest: TransactionRequest;
  composeTransaction: ComposeTransaction;
  stakingSign: stakingSign;
  invalidAmmount = false;
  isSigning = false;
  allowedDecimals = false;
  ledgerDeviceClickSign = false;
  // ledgerDeviceSignText: string;
  recive_string: string;
  usdAmount: number = null;
  selectedWallet: any;
  selectedAsset: any;
  selectedValidator: any;
  _wallets: any;
  status: string;
  stakeAmount: number;
  comment: string = "";
  assets: any;
  _enableStakeWalletData: any = [];
  validatorList: any;
  deviceName: any;
  USDAmount: number = 0;
  featureStaking: boolean;
  userType: any;
  liminalTerm: boolean;
  stakeAmtIsEditable:boolean=false;
  stakePopupHeader:string = 'Unstake Asset';

  selectedAddressAmount: addressAmountPair = {
    policy: null,
    address: null,
    amount: null,
  };
  public walletAutocompleteControl = new FormControl("", Validators.required);
  @Input() component_for: string;
  @Input() stakingApproveData: any;
  @Input() stakeWalletData: any;
  @Input() enableStakeAssetsData: any;
  @Input() enableStakeWalletData: any;
  @Input() stakingBtnType: string;
  @Input() assetUsd: string;
  @Input() lock_time: any;
  @Input() assetEnableStatus: boolean;
  

  browser: string;
  device: string;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private data: DataService,
    private httpService: HttpService,
    private walletService: WalletserviceService,
    private logger: LoggerService,
    private modalService: NgbModal,
    public authService: AuthServiceJWT,
    private segment: SegmentService,
    private apiService: ApiService
  ) {
    this.browser = new UAParser().getBrowser().name;

    // this.ledgerDeviceSignText = this.deviceLedgerSignText();

    this.data.wallets.pipe(takeUntil(this.onDestroy$)).subscribe((wallets) => {
      if (wallets) {
        this.wallets = wallets;
      }
    });

    this.data.getUserProfile.subscribe(async (user) => {
      if (!user) return;
      let features = user["organizations"][0]["features"]
      this.featureStaking = features.some(feature => OrgFeatureName.STAKING === feature.toLowerCase());
      
      this.userType = user["organizations"][0]["type"];
      // this.featureStaking = false;
      // console.log('dc',user["organizations"][0]["features"].includes("mmi"));
    });
  } //end constructor

  ngOnInit(): void {
    // this.stakingData = this.stakingApproveData;
    // console.log('assetUsd',this.assetUsd);
    this.enableStakeWalletData
      ? (this._enableStakeWalletData = this.enableStakeWalletData)
      : (this._enableStakeWalletData = []);
    // console.log('wallet',this._enableStakeWalletData);
    // console.log('wallet',this.enableStakeWalletData);
  }

  async enableStakeWalletFilter() {
    // console.log(this.stakeAsset);

    // if(this.stakingBtnType == 'start-stake-header')
    // {
    //   this._wallets = this.enableStakeAssetsData.filter(e => e.isStakingPolicyEnable == false);
    //   this._wallets = [...new Map(this._wallets.map(item => [item.wallet.id, item])).values()];
    // } else {
    //   this._wallets = this.enableStakeAssetsData.filter(e => e.isStakingPolicyEnable == false && e.wallet.coin == this.stakeAsset.assets);
    //   this._wallets = [...new Map(this._wallets.map(item => [item.wallet.id, item])).values()];
    // }
    console.info(
      "inside fn data",
      this.enableStakeAssetsData,
      this.enableStakeAssetsData.isAssetStakingSupported
    );
    this.enableStakeWalletData
      ? (this._enableStakeWalletData = this.enableStakeWalletData)
      : (this._enableStakeWalletData = []);

    // this.assets = this.enableStakeAssetsData;
    this.enableStakeAssetsData.length
      ? (this.assets = this.enableStakeAssetsData.filter(
          (e) => e?.isAssetStakingSupported && e?.enabledAsset
        ))
      : (this.assets = this.enableStakeAssetsData);

    if (!this.assets.length) {
      this.selectedAsset = this.assets;
      this.selectedValidator = this.assets.listOfValidators[0];
      this._wallets = this._enableStakeWalletData.filter(
        (e) =>
          e.wallet.coin == this.selectedAsset.assets &&
          e.wallet.chain == this.selectedAsset.chain
      );
      this._wallets = [
        ...new Map(
          this._wallets.map((item) => [item.wallet.id, item])
        ).values(),
      ];
      console.log(this.selectedAsset);
    }
    // if(this.stakingBtnType == 'start-stake-header')
    // {
    //   this.assets = this.enableStakeAssetsData;
    //   else {
    //   this.assets = this.enableStakeAssetsData.filter(e => e.wallet.coin == this.stakeAsset.assets);
    // }
  }

  stakeAdd(stage?:any) {
this.stakeAmtIsEditable=false;
    this.selectedWallet = this.stakeWalletData.wallet;
    console.info("selected wallet details",this.selectedWallet);
    
    if (this.selectedWallet.chain.toLowerCase() == "cardano") {
      this.maxStake();
    }
    if (this.selectedWallet.chain.toLowerCase() == "sol") {
      this.solanaAmounts(stage);
    }
    this.selectedAsset = this.stakeWalletData.wallet;
    this.selectedValidator =
      this.stakeWalletData.stakingEnabledValidatorDetails[0];
    let userProfile = JSON.parse(localStorage.getItem("userProfile"));
    this.deviceName = this.stakeWalletData.walletKeys.filter(
      (e) => e.userid == userProfile.userId
    )[0]?.provider;
    // console.log(userProfile);
    // console.log(this.deviceName);
    // console.log(this.stakeWalletData);

    // this.selectedWallet = this.wallets.filter(e => e.id == this.stakeWalletData.wallet.id && e.coin == this.stakeWalletData.wallet.coin && e.chain == this.stakeWalletData.wallet.chain)[0];
    // this.selectedAsset = this.wallets.filter(e => e.id == this.stakeWalletData.wallet.id && e.coin == this.stakeWalletData.wallet.coin && e.chain == this.stakeWalletData.wallet.chain)[0];
    // this.device = this.selectedAsset.walletKeys.filter(item => item.ismine === true)[0].provider;
    // console.log(this.selectedWallet);
    // console.log(this.selectedAsset);
  }

  // pretty(number, decimal) {
  //   return toPretty(number, decimal)
  // }

  groupBy<T extends Record<string, any>, K extends keyof T>(
    array: T[],
    key: K | { (obj: T): string }
  ): Record<string, T[]> {
    const keyFn = key instanceof Function ? key : (obj: T) => obj[key];
    return array.reduce((objectsByKeyValue, obj) => {
      const value = keyFn(obj);
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {} as Record<string, T[]>);
  }

  // sumofwallets() {
  //   let total = new BN(0);
  //   if (this.wallets && this.wallets.length > 0)
  //     for (let i = 0; i < this.wallets.length; i++) {
  //       total = total.plus(new BN(this.wallets[i].balanceUSD));
  //     }
  //   return total
  // }

  open(content) {
    if (!this.featureStaking && this.component_for == "staking-enable" && this.stakingBtnType == 'start-stake-header') {
      this.segment
      .track("start-staking-requested", this.authService.getUser)
      .then(() => this.logger.info("Start staking requested")).catch((err)=>{});
    
      this.status = "step-staking-disabled";
    } else if ((!this.featureStaking || !this.enableStakeAssetsData?.enabledAsset) && this.component_for == "staking-enable" && this.stakingBtnType == 'asset-start-staking') {
      this.segment
      .track("start-staking-requested", this.authService.getUser)
      .then(() => this.logger.info("Start staking requested")).catch((err)=>{});
    
      this.status = "step-staking-disabled";
    } else if (this.component_for == "staking-enable") {
      this.enableStakeWalletFilter();
      this.status = "step-0";
      console.info("log data here :", this.assets);
    } else if (this.component_for == "staking-add") {
      this.stakeWalletData?.sumBalance?.total_staking_amount > 0 &&
      this.stakeWalletData?.wallet.parentchain.toLowerCase() == "cardano"
        ? (this.status = "cardano_already_staked")
        : (this.status = "step-1");
    } else if (
      this.component_for == "unstake" &&      this.stakeWalletData.wallet.parentchain.toLowerCase() !== "cardano"
    ) {
      this.status = "step-2";
    } else if (
      this.component_for == "unstake" &&
      this.stakeWalletData.wallet.parentchain.toLowerCase() == "cardano"
    ) {
      this.status = "cardano";
    } else if (
      this.component_for == "claim" &&
      this.stakeWalletData.wallet.parentchain.toLowerCase() !== "cardano"
    ) {
      this.status = "step-3";
    } else if (
      this.component_for == "claim" &&
      this.stakeWalletData.wallet.parentchain.toLowerCase() == "cardano"
    ) {
      this.status = "cardano-claim";
    }

    this.isSigning = false;

    // if(this.status == 'step-3')
    // {
    //   this.device = this.stakingData.walletinfor.walletKeys.filter(item => item.ismine === true)[0].provider;
    // }
    
    this.modalService
      .open(content, { windowClass: "modal-custom-background", centered: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.resetSendModel();
        }
      );
  }

  resetSendModel() {
    this.stakeAmount = null;
    this.comment = "";
    this.status = "";
    this.selectedAddressAmount = { policy: null, address: null, amount: null };
    this.txRequest = null;
    this.recive_string = "";
    this.selectedWallet = null;
    this.selectedAsset = null;
    this.selectedValidator = null;
    this.searchWalletInput = "";
    this.searchAssetInput = "";
    this.liminalTerm = false;
  }

  public ngOnDestroy(): void {
    this.searchWalletInput = "";
    this.searchAssetInput = "";
    this.onDestroy$.next();
  }
  //On Press of Confirm button before signing
  // async onSubmit(amount?) {
  //     this.selectedAddressAmount.address = this.stakingData.destinationAddress;
  //       this.logger.info(this.stakingData.walletinfor.policy, this.selectedAddressAmount.address);
  //       this.logger.info(this.stakingData.walletinfor.policy.filter(p => p.condition == this.selectedAddressAmount.address));
  //       this.selectedAsset = this.walletService.getWallet(this.stakingData.walletid + "_" + this.stakingData.asset);
  //       // this.device = this.selectedAsset.walletKeys.filter(item => item.ismine === true)[0].provider;
  //       this.selectedAddressAmount.policy = this.stakingData.walletinfor.policy.filter(p => p.condition == this.selectedAddressAmount.address)[0]
  //   if (amount)
  //     this.selectedAddressAmount.amount = amount

  //   if (this.selectedAddressAmount.address && this.selectedAddressAmount.amount && this.selectedAddressAmount.amount >= 0) {
  //     try {
  //       this.segment.track("send-asset-amount-and-address-confirmed", this.authService.getUser)
  //         .then(() => this.logger.info("Send asset amount and address confirmed"));
  //     } catch (err) {
  //       this.logger.error(err, err);
  //     }

  //     this.composeTransaction = new ComposeTransaction(this.selectedAsset, this.data, this.httpService, this.logger)
  //   } else {
  //     this.resetSendModel()
  //     this.selectedAddressAmount = { policy: null, address: null, amount: null }
  //     try {
  //       this.segment.track("send-asset-amount-and-address-confirm-failed", this.authService.getUser)
  //         .then(() => this.logger.info("Send asset amount and address confirmation failed"));
  //     } catch (err) {
  //       this.logger.error(err, err);
  //     }

  //     return;
  //   }
  // }

  // deviceLedgerSignText() {
  //   return "Open Ether app in your Ledger device and click Sign"
  // }

  // After on submit we press Sign button
  async onSign(amount, comment, wallet, validator, type) {
    this.stakingSign = new stakingSign(
      this.selectedWallet,
      this.data,
      this.httpService,
      this.logger,
      this.authService,
      this.walletService,
      this.segment,
      this.apiService
    );
    this.isSigning = true;

    this.segment
      .track(
        "staking-asset-transaction-sign-attempted",
        this.authService.getUser
      )
      .then(() => this.logger.info("Staking asset transaction sign attempted"))
      .catch((err) => {
        console.error(err);
      });
    // console.log(amount);
    // console.log(comment);
    // console.log(wallet);
    // console.log(validator);
    // console.log(type);
    try {
      let signResponse = await this.stakingSign.stakingTransaction(
        amount,
        comment,
        wallet,
        validator,
        type
      );
      this.isSigning = false;
      for (let i = 0; i < signResponse.length; i++) {
        if (signResponse[i].error) {
          this.modalService.dismissAll();
          ErrorPrompt.fire({
            icon: "error",
            title: "Failed to sign transaction!",
            // text:
            //   signResponse[i].error.charAt(0).toUpperCase() +
            //   signResponse[i].error.slice(1),
            text: this.walletService.setCustomErrorMsg(signResponse[i].error),
            showConfirmButton: false,
          });

          this.segment
            .track(
              "send-asset-transaction-sign-failed",
              {user: this.authService.getUser, error: signResponse[i]}
            )
            .then(() => this.logger.info("Send asset transaction sign failed"))
            .catch((err) => {
              console.error(err);
            });
          this.resetSendModel();
          return;
        }
      }
      // this.modalService.dismissAll();
      // Swal.fire({
      //   title: 'Success!',
      //   text: 'Transaction has been signed!',
      //   icon: 'success',
      //   confirmButtonText: 'Cool'
      // });
      this.status = "success";
      this.segment
        .track(
          "send-asset-transaction-sign-success",
          this.authService.getUser
        )
        .then(() => this.logger.info("Send asset transaction sign success")).catch((err)=>{});
     
      this.refreshPendingTx();
    } catch (err) {
      console.log(err);
      // this.component_for='';
      this.modalService.dismissAll();
      ErrorPrompt.fire({
        icon: "error",
        title: "Something went wrong",
        text: err.error.message,
        showConfirmButton: false,
      });
    }
  }

  unstake() {
    Swal.fire({
      // position: 'top-end',
      icon: "success",
      title: "Unstake",
      text: "To unstake, simply transfer your ADA to a different non-staking wallet",
      showConfirmButton: false,
      // timer: 1500
    });
  }

  async refreshPendingTx() {
    let txRequests = await this.httpService
      .getAllPendingWalletTransactions()
      .toPromise();
    this.data.changePendingTxRequest(txRequests);

    // let wallets = await this.httpService.getAllMultisigWallets().toPromise();
    // this.data.changeWallets(wallets);
  }

  decimalPlaces(num) {
    var match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
      return 0;
    }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0) -
        // Adjust for scientific notation.
        (match[2] ? +match[2] : 0)
    );
  } //end

  // resetAmmountValidationErrormessages() {
  //   this.invalidAmmount = false;
  //   this.allowedDecimals = false;
  // }//end

  async transactionAmmountValidations(val: number) {
    this.USDAmount = val * parseFloat(this.assetUsd);
    if (val != undefined) {
      if (Number(val) > Number(this.stakeWalletData.assetBalance)) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
      } else if (Number(val) < 0.00001) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
      } else if (this.decimalPlaces(val) > 5) {
        this.allowedDecimals = true;
        this.invalidAmmount = false;
      } else if (
        (this.selectedWallet.balance < val &&
          this.component_for == "staking-add") ||
        (this.stakeWalletData?.sumBalance?.total_staking_amount < val &&
          this.component_for == "unstake")
      ) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
      } else if(this.selectedWallet.parentchain.toLowerCase() == 'evm' && !(val % 32 === 0)) {
        this.invalidAmmount = true;
        this.allowedDecimals = false;
      } else {
        this.allowedDecimals = false;
        this.invalidAmmount = false;
      }
    } else {
      this.invalidAmmount = false;
      this.allowedDecimals = false;
    }
  } //end

  selectWallet(wallet) {
    // this.assets = this.enableStakeAssetsData.filter(e => e.wallet.id == wallet.wallet.id);
    // if(this.assets.length == 1)
    // {
    //   this.selectedAsset =  this.assets[0].wallet;
    // }
    this.selectedWallet = wallet.wallet;
    // this.validatorList = wallet.listOfValidators;
    // if(this.validatorList.length == 1)
    // {
    //   this.selectedValidator = wallet.listOfValidators[0];
    // }
  }

  selectAssets(assets) {
    this.selectedAsset = assets;
    this.validatorList = assets.listOfValidators;
    if (this.validatorList.length == 1) {
      this.selectedValidator = assets.listOfValidators[0];
    }
    this._wallets = this._enableStakeWalletData.filter(
      (e) => e.wallet.coin == this.selectedAsset.assets
    );
    this._wallets = [
      ...new Map(this._wallets.map((item) => [item.wallet.id, item])).values(),
    ];
  }

  selectValidator(data) {
    this.selectedValidator = data;
  }

  // async senderAssetsConfirm()
  // {
  //   this.selectedAddressAmount.address = this.selectedValidator.condition;
  //   this.selectedAddressAmount.policy = this.selectedValidator;
  //   let res = await this.httpService.getRate(this.selectedAsset.coin).toPromise();
  //   this.usdAmount = parseFloat(JSON.parse(res)['usd']);
  // }

  // async addStaking(id,ass_address,amount,comment)
  // {

  //   this.isSigning = true;
  //   try {
  //     const response = await this.httpService.addStaking(id, amount, ass_address,comment).toPromise();

  //     console.log(response);
  //     if (response.success === true) {
  //       this.status = "success";
  //       this.isSigning = false;
  //     } else {
  //       this.modalService.dismissAll();
  //       this.isSigning = false;
  //       Swal.fire({
  //         icon: "error",
  //         title: "Failed to add staking",
  //         text: response.message,
  //         showConfirmButton: false,
  //       })
  //     }
  //   } catch (e) {
  //     this.modalService.dismissAll();
  //     Swal.fire({
  //       icon: "error",
  //       title: "Failed to add staking",
  //       text: e.error.message,
  //       showConfirmButton: false,
  //     })
  //     console.log(e);
  //   }
  //   this.refreshPendingTx();
  // }
  enableStaking(walletId, validatorId) {
    this.segment
      .track("start-staking-initiated", this.authService.getUser)
      .then(() => this.logger.info("Start staking initiated")).catch((err)=>{});
   
    this.isSigning = true;
    this.authService
      .writeAccess(scopes.StakingEnable)
      .subscribe(async (accessToken) => {
        try {
          const response = await this.httpService
            .enableStaking_v2(walletId, validatorId, accessToken)
            .toPromise();
          console.log("response Data", response);
          if (response.success === true) {
            this.status = "success";
            this.isSigning = false;
          } else {
            this.modalService.dismissAll();
            this.isSigning = false;
            ErrorPrompt.fire({
              icon: "error",
              title: "Failed to Enable staking",
              text: response.message,
              showConfirmButton: false,
            });
          }
        } catch (e) {
          this.modalService.dismissAll();
          ErrorPrompt.fire({
            icon: "error",
            title: "Failed to Enable staking",
            text: e.error.message,
            showConfirmButton: false,
          });
          console.log(e);
        }
      });
    this.refreshPendingTx();
  }

  nextStaking() {
    this.status = "step-1.1";
  }

  maxStake() {
    if (
      !this.stakeWalletData?.hasRegistered &&
      this.selectedWallet.parentchain.toLowerCase() == "cardano"
    ) {

      this.stakeAmount = 2;
      this.USDAmount = this.stakeAmount * parseFloat(this.assetUsd);
    } else {
      this.stakeAmount = this.selectedWallet.balance;

      this.USDAmount = this.stakeAmount * parseFloat(this.assetUsd);
    }
    
    if(this.selectedWallet.parentchain.toLowerCase() == 'evm') {
      if(!(this.stakeAmount % 32 === 0) || !(this.stakeAmount > 0))
      {
        this.invalidAmmount = true;
        this.allowedDecimals = false;  
      } else {
        this.invalidAmmount = false;
        this.allowedDecimals = false;
      }
    }
  
}
solanaAmounts(stage){
    if(stage=='stake'){
      this.stakePopupHeader='Stake Asset';
      if(this.stakeWalletData.sumBalance.hasOwnProperty('total_create_amount') ){
        this.stakePopupHeader='Stake Asset';
      }
      else{
        this.stakePopupHeader='Create Account';

      }
      this.stakeAmount=parseFloat(Number(this.stakeWalletData.sumBalance?.total_create_amount).toFixed(5));
    this.stakeWalletData.sumBalance?.total_create_amount >0 ?  this.stakeAmtIsEditable=true : this.stakeAmtIsEditable=false
    }
    else if(stage=='unstake'){
      this.stakePopupHeader='Unstake Asset';
      this.stakeAmount= parseFloat(Number(this.stakeWalletData.sumBalance?.total_staking_amount).toFixed(5));
    this.stakeAmtIsEditable=true 
    }
    else if(stage=='withdrawl'){
      if(this.stakeWalletData.sumBalance.total_unstaked_amount > 0 ){
        this.stakePopupHeader='Withdraw Asset';
      }
      else{
        this.stakePopupHeader='Unstake Asset';
      }
      this.stakePopupHeader='Withdraw Asset';
      this.stakeAmount=parseFloat(Number(this.stakeWalletData.sumBalance?.total_unstaked_amount).toFixed(5));
    this.stakeAmtIsEditable=true 
    }
    
}
}
