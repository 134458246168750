<div *ngIf="component_for == 'dev_Onboard'">
    <div class="developer-option-card card">
        <div class="card-body">
            <div class="d-flex align-items-center mb-2">
                <!-- <i class="icon-key mr-2"></i> -->
                <span class="d-block font-size-16 font-weight-600 mb-1">API Key</span>
            </div>
            <div class="d-flex justify-content-between">
                <div class="policy-type-text text-dark d-inline-flex align-items-center">
                    Generate client ID and secret to access wallets using {{buildName}}'s API and SDK.
                </div>
                <div class="d-inline-flex">
                    <!-- <span *ngIf="!apiDetails?.data.length" (click)="generate_Key()" class="border-bottom-blue text-blue cursor-pointer">Generate Key</span>
                    <span ngbTooltip="Please contact support to generate additional API keys" *ngIf="apiDetails?.data.length " class="border-bottom-blue text-blue disabled">Generate Key</span> -->
                    <span
                        [ngbTooltip]="validAPIkeys?.length && 'Please contact support to generate additional API keys'">
                        <button appDisableClick class="btn btn-primary btn-round font-size-14"
                            [ngClass]="{'disabled':validAPIkeys?.length}"
                            [disabled]="validAPIkeys?.length  || !sdkEnabled" (click)="generate_key_open()">Generate
                            Key</button>
                    </span>
                    <!-- <button class="btn btn-primary btn-round btn-sm font-size-14" (click)="generate_key_open()"><i class="icon-key mr-2"></i>Generate Key</button> -->
                </div>
            </div>
        </div>
        <hr class="m-0">
        <div class="card-body">
            <div class="table-responsive" *ngIf="apiDetails?.data.length">
                <table class="table table-dev-onbording table-borderless">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left pl-0 pt-0">Name</th>
                            <th scope="col" class="text-left pt-0">Email</th>
                            <th scope="col" class="text-left pt-0">Client ID</th>
                            <th scope="col" class="text-left pt-0">Generated On</th>
                            <th scope="col" class="text-left pt-0">Expiration Date</th>
                            <th scope="col" class="text-left pt-0">Role</th>
                            <th scope="col" class="text-left pt-0" style="width: 275px;">Signer</th>
                            <th scope="col" class="text-center pt-0">Status</th>
                            <th scope="col" class="text-left pt-0"></th>
                            <th scope="col" class="text-left pt-0"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let details of apiDetails.data">
                            <td class="pl-0">{{details.name}}</td>
                            <td>{{details.email}}</td>
                            <td>
                                <div class="d-flex align-items-center">{{ details.clientId }} <i
                                        class="icon-eye text-dark ml-2"></i></div>
                            </td>
                            <td>{{ apiKeyService.dateConversion(details.createdAt)}}</td>
                            <td
                                [ngClass]="{'text-danger': details.status == UserStatus.API_KEY_DELETED_OR_DISABLED || details.expired}">
                                {{ apiKeyService.dateConversion(details?.keyExpiration) }} <br>{{details.status ==
                                UserStatus.API_KEY_DELETED_OR_DISABLED ? details.expired ? 'Expired' : 'Deleted' : ''}}
                            </td>
                            <td>{{ details.role | titlecase }}</td>
                            <td>
                                <ul class="d-flex flex-wrap">
                                    <li class="pills pills-light-dark font-size-12 mr-2 mt-1"
                                        *ngFor="let key of details.providers">
                                        {{key}}
                                    </li>
                                    <!-- <li class="pills pills-blue font-size-12 mr-2">MPC</li>
                                    <li class="pills pills-secondary font-size-12 mt-1">MPC Setup Pending</li> -->
                                </ul>
                            </td>
                            <td class="text-center">
                                <app-api-key-status [details]="details"></app-api-key-status>
                            </td>
                            <td>
                                <div ngbDropdown container="body" [ngbTooltip]="
                                    details.status == UserStatus.API_KEY_DELETED_OR_DISABLED && details?.expired ?
                                    'API key expired' :
                                    details.status == UserStatus.API_KEY_DELETED_OR_DISABLED ?
                                    'API Key deleted' : ''
                                  ">
                                    <button class="dropDown-btn" ngbDropdownToggle
                                        [disabled]="details.status == UserStatus.API_KEY_DELETED_OR_DISABLED">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg>

                                        <div ngbDropdownMenu style="border-radius: 12px">
                                            <div ngbDropdownItem class="p-0 dropdown-item-custom"
                                                *ngFor="let item of dropdownMenus">
                                                <li (click)="handleDropdownMenu(item, details)" class="ml-2"
                                                    [ngClass]="{'not-clickable ':item.disabled}">
                                                    <span *ngIf="item.id == 1" class="mr-2">
                                                        <div *ngIf="details.status == UserStatus.INACTIVE"
                                                            class="enable_dot d-inline-block"></div>
                                                        <div *ngIf="details.status == UserStatus.ACTIVE"
                                                            class="archived_dot d-inline-block"></div>
                                                    </span>
                                                    <!-- <i class="{{item.icon}} mt-1 mr-2 pl-1 i-icon" ></i> -->
                                                    <img src="../../../../assets/images/{{item.icon}}" class=" mr-2"
                                                        *ngIf="item.id != 1" />
                                                    <span style="margin-left: 2px">
                                                        {{item.id == 1 ? details?.status == UserStatus.INACTIVE ?
                                                        'Enable' : 'Disable' : ''}} {{item.name}}
                                                    </span>
                                                </li>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </td>
                            <td class="text-right">
                                <!-- <app-enable-mobile [member]="details" [component_for]="'dev_api_key'"></app-enable-mobile> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="developer-option-card card mt-4">
        <div class="card-body">
            <div class="d-flex align-items-center mb-2">
                <!-- <i class="icon-key mr-2"></i> -->
                <span class="d-block font-size-16 font-weight-600 mb-1">Webhook</span>
            </div>
            <div class="d-flex justify-content-between">
                <div class="policy-type-text text-dark  d-inline-flex align-items-center">
                    Configure webhook URL to receive real time notifications on transactions and alerts from {{buildName}}
                </div>
                <div class="d-inline-flex">
                    <span [ngbTooltip]="userType!=2 && 'Please contact Admin to configure webhook URL'">
                        <button appDisableClick class="btn btn-secondary-dark btn-round font-size-14 mr-2"
                            [ngClass]="{'disabled': userType != userRole.admin || !sdkEnabled || !webhook}"
                            [disabled]="userType != userRole.admin || !sdkEnabled || !webhook"
                            (click)="goToWebhookHistory()">History</button>
                        <button appDisableClick class="btn btn-primary btn-round font-size-14"
                            [ngClass]="{'disabled': userType != userRole.admin || !sdkEnabled}"
                            [disabled]="userType != userRole.admin || !sdkEnabled"
                            (click)="open_webhook(configure_webhook)">Configure</button>
                        <!-- <span *ngIf="userType==2" class="text-blue cursor-pointer w-100" (click)="open_webhook(configure_webhook)">Configure Webhook</span>
                    <span ngbTooltip="Please contact Admin to configure webhook URL" *ngIf="userType!=2" class="text-blue w-100 disabled">Configure Webhook</span> -->
                    </span>
                </div>
            </div>
        </div>
        <hr class="m-0">
        <div class="card-body">
            <div class="table-responsive styled-table" *ngIf="webhook">
                <table class="table table-dev-onbording table-borderless">
                    <thead>
                        <tr>
                            <th scope="col" class="text-left pl-0 pt-0">Webhook URL</th>
                            <th scope="col" class="text-left pt-0">Signing Key</th>
                            <th scope="col" class="text-left pt-0">Generated on</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="pl-0">{{webhook.webhook_url}}</td>
                            <td><span class="cursor-pointer" ngxClipboard [cbContent]="webhook.signing_key"
                                    placement="top" triggers="click:blur"
                                    ngbTooltip="Copied">{{webhook.signing_key}}</span></td>
                            <td>{{webhook.updated_at | date: 'dd-MMM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<!-- <div class="card" *ngIf="component_for != 'dev_Onboard'">
    <div class="card-body p-4">
        <div class="d-flex justify-content-between mb-3">
            <div class="d-flex align-items-center">
                <h2 class="text-dark m-0">
                    Developer Options
                </h2>
            </div>
        </div>
        <div class="developer-option-card card">
            <div class="card-body">
                <div class="d-flex align-items-center mb-2">
                    <i class="icon-key mr-2"></i>
                    <span class="d-block font-size-16 text-dark m-0">API Key</span>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="policy-type-text text-dark  d-inline-flex align-items-center">
                        Generate client ID and secret to access wallets using Liminal's API and SDK.
                    </div>
                    <div class="d-inline-flex">
                        <span *ngIf="!apiDetails?.data.length " (click)="generate_key_open()" class="border-bottom-blue text-blue cursor-pointer">Generate Key</span>
                        <span ngbTooltip="Please contact support to generate additional API keys" *ngIf="apiDetails?.data.length " class="border-bottom-blue text-blue disabled">Generate Key</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive styled-table mt-3" *ngIf="apiDetails?.data.length">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" class="text-left">Client ID</th>
                        <th scope="col" class="text-left">Generated on</th>
                        <th scope="col" class="text-left">Role</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let details of apiDetails.data">
                        <td>{{ details.clientId }}</td>
                        <td>{{ details.createdAt | date: 'dd-MMM-yyyy' }}</td>
                        <td>{{ details.role | titlecase }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="developer-option-card card mt-4">
            <div class="card-body">
                <div class="d-flex align-items-center mb-2">
                    <i class="icon-key mr-2"></i>
                    <span class="d-block font-size-16 text-dark m-0">Webhook</span>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="policy-type-text text-dark  d-inline-flex align-items-center">
                        Configure webhook URL to receive real time notifications on transactions and alerts from Liminal
                    </div>
                    <div class="d-inline-flex webhook-btn w-25  text-right" >
                        <span *ngIf="userType==2" class="text-blue cursor-pointer w-100" (click)="open_webhook(configure_webhook)">Configure Webhook</span>
                        <span ngbTooltip="Please contact Admin to configure webhook URL" *ngIf="userType!=2" class="text-blue w-100 disabled">Configure Webhook</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-responsive styled-table mt-3" *ngIf="webhook">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" class="text-left">Webhook URL</th>
                        <th scope="col" class="text-left">Signing Key</th>
                        <th scope="col" class="text-left">Generated on</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{webhook.webhook_url}}</td>
                        <td><span class="cursor-pointer" ngxClipboard [cbContent]="webhook.signing_key" placement="top" triggers="click:blur" ngbTooltip="Copied">{{webhook.signing_key}}</span></td>
                        <td>{{webhook.updated_at | date: 'dd-MMM-yyyy'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->

<ng-template #generate_key let-modal>
    <ng-container *ngIf="step == 'step-1'">
        <div class="modal-header">
            <h2 class="modal-title"><i class="icon-key mr-2"></i>Generate API Key</h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="mb-4">
                        <span class="font-size-12 mb-2 d-block font-weight-600 text-dark">Name</span>
                        <label class="font-size-12 mb-2 d-block text-dark">Assign name for API Key</label>
                        <div class="d-flex align-items-center pl-0 position-relative">
                            <input class="form-control" type="text" [(ngModel)]="userName" placeholder="Enter Name">
                        </div>
                    </div>
                    <div class="mb-4">
                        <span class="font-size-12 mb-2 d-block font-weight-600 text-dark">Expiry Date</span>
                        <div class="pl-0 position-relative generate-key-dp ">
                            <app-date-selection [placeholder]="'Choose an expiration date for the API key'" [minDate]="today" [maxDate]="maxRange"
                                [(selectedDate)]="keyExpiration" [readonly]="true" (dateChange)="handleDateChange($event)">
                            </app-date-selection>
                        </div>
                    </div>
                    <div class="mb-4">
                        <span class="font-size-12 mb-2 d-block font-weight-600 text-dark">Notification</span>
                        <label class="font-size-12 mb-2 d-block text-dark">We will send transaction notifications to
                            this email</label>
                        <div class="d-flex align-items-center pl-0 position-relative">
                            <input class="form-control" type="email" [(ngModel)]="userEmail" email
                                #verifyEmail="ngModel" placeholder="Enter Email">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-outline-primary w-100"
                [ngClass]="{'disabled' : !userName || !userEmail || isLoading || verifyEmail.invalid || !keyExpiration}"
                [disabled]="!userName || !userEmail || isLoading || verifyEmail.invalid || !keyExpiration"
                (click)="generate_Key(userName,userEmail,keyExpiration)">
                <div *ngIf="isLoading" class="loader-box">
                    <div class="loader-15"></div>
                </div>
                <ng-container *ngIf="!isLoading">Generate Now</ng-container>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="step == 'step-2'">
        <div class="modal-header">
            <h2 class="modal-title"><i class="icon-key mr-2"></i>API Key generated</h2>
            <button type="button" class="close" aria-label="Close" (click)="close()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="mb-4">
                        <label>Client ID</label>
                        <div class="d-flex align-items-center pl-0 position-relative">
                            <input disabled class="form-control" type="text" value="{{apiData?.data.clientId}}">
                            <div class="position-absolute copy_Icon">
                                <i class="icon-copy text-blue cursor-pointer" ngxClipboard
                                    [cbContent]="apiData?.data.clientId" placement="left" triggers="click:blur"
                                    ngbTooltip="Copied"></i>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <label>Client Secret</label>
                        <div class="d-flex align-items-center pl-0 position-relative">
                            <input disabled class="form-control pr-4"
                                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" type="text"
                                value="{{apiData?.data.clientSecret}}">
                            <div class="position-absolute copy_Icon">
                                <i class="icon-copy  text-blue cursor-pointer" ngxClipboard
                                    [cbContent]="apiData?.data.clientSecret" placement="left" triggers="click:blur"
                                    ngbTooltip="Copied"></i>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 mt-164">
                        <label class="ip-lable">
                            Currently, any IP can access your wallets by using this API key. <span (click)="validAPIkeys?.length && restrictIpAddress()" class="l-color font-weight-500">Click here</span> to specify particular IP addresses for restricted access.
                        </label>
                    </div>
                    <div class="mb-3">
                        <div class="d-flex p-3 info-box-gk">
                            <img src="../../../../assets/images/info.svg" class="pr-1" />
                            <span class="d-inline-flex pr-3"> API key can be viewed only once. Please store it
                                securely!</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-primary w-100" (click)="downloadAPI()">
                Download client ID and secret
            </button>
        </div>
    </ng-container>
</ng-template>

<ng-template #configure_webhook let-modal>
    <div *ngIf="stage=='webhook_url'" class="modal-custom">
        <div class="modal-header border-0 mx-2">
            <h2 class="modal-title text-dark"><i class="icon-key mr-2"></i>Webhook</h2>
            <button type="button" class="close" aria-label="Close" (click)="webhook_close()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body mx-3">
            <div class="row">
                <div class="col-12">
                    <div class="mb-4">
                        <label>Configure webhook URL to receive realtime notifications on transactions and alert from
                            liminal.</label>
                    </div>
                    <div class="mb-4">
                        <label class="ml-1 text-dark font-weight-semibold">Webhook URL</label>
                        <input #verifyHook="ngModel" class="form-control" [(ngModel)]="webhook_Url" type="text"
                            pattern="^(https?:\/\/)?(?!(localhost))([a-zA-Z\d-]+(\.[a-zA-Z\d-]+)*(\.[a-zA-Z]{2,})?)(:\d{1,5})?(\/.*)?$"
                            placeholder="https://example.com/webhook" />
                        <!-- <div class="mt-2 text-danger" *ngIf="submitted && f.webhook_address.errors">Not a valid URL</div> -->
                    </div>
                    <!-- <div>
            <input type="checkbox"  class="mr-2"/>
            <label> Transaction Alerts</label><br />
            <input type="checkbox" class="mr-2"/>
            <label> Errors</label><br />
          </div> -->
                </div>
            </div>
        </div>
        <div class="modal-footer border-0 mx-2">
            <button *ngIf="webhook_Url && !verifyHook.invalid" type="submit" (click)="update_Webhook()"
                class="btn btn-primary w-100 mb-2">Configure Webhook</button>
            <button *ngIf="!webhook_Url || verifyHook.invalid" type="submit"
                class="btn btn-primary w-100 mb-2 disabled">Configure Webhook</button>
        </div>
    </div>


    <div *ngIf="stage=='success'" class="modal-custom">
        <div class="modal-header border-0">
            <h2 class="modal-title">
                <i class="icon-key mr-2"></i>
                Webhook
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="webhook_close()"> <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body d-flex align-items-center">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3 position-relative">
                        <div class="background_success text-center"> <img class="w-50"
                                src="../../../assets/images/check-outline-success.gif"> </div>
                    </div>
                    <div class="mb-5 text-center">
                        <div class="h5">Success</div>
                        <div class="text-muted">Awesome! We have received your request to update webhook URL. 🎉</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button title="" type="submit" class="btn btn-outline-primary w-100" (click)="webhook_close()"> Done
            </button>
        </div>
    </div>
</ng-template>

    <ng-template #rotateKey let-modal>
        <app-rotate-keys (close)="closeRotateKey($event)" [userDetails]="currentDetails"></app-rotate-keys>
    </ng-template>

<app-ip-address-restriction [openIpAddressRestrictModal]="openIpAddressRestrictModal"
    [currentIpDetails]="currentDetails"></app-ip-address-restriction>