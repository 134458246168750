import {Title} from '@angular/platform-browser';
import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    Inject,
    LOCALE_ID, OnDestroy
} from '@angular/core';
import {Transaction, TxStatusOptions} from 'src/app/shared/entities/Transaction';
import {TxHistoryService} from 'src/app/shared/services/tx-history.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {csvGeneration, capitalizeFirstLetter} from 'src/app/shared/helpers/general';
import {environment} from 'src/environments/environment';
import {getTimeInLocalimeZone} from '../../shared/utils/timezone';
import {PaginationMeta} from '@entities/teams.model';
import {formatDate} from '@angular/common';
import { Router } from '@angular/router';


var moment = require("moment");

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit, OnDestroy {
    pageTitle = `${environment.buildName} Vaults - History`;
    downloading: boolean = false;
    txSuccessCount: Number = 0;
    txPendingCount: Number = 0;
    txFailCount: Number = 0;
    successButtonActive: boolean = true;
    pendingButtonActive: boolean = false;
    failedButtonActive: boolean = false;
    page = 1;
    currentStatus: TxStatusOptions = TxStatusOptions.CONFIRMED;
    pageSize = 10;
    fromDate: any;
    toDate: any;
    filter = '';
    txs = null;
    loading: boolean = true;
    paginationMetaData: PaginationMeta = {}
    TxStatus = TxStatusOptions;
    searchChanged = new Subject<string>();
    @Input() transactions_component_for: string;
    @Input() walletDetailWalletId: any;
    @ViewChild("transactionalert") transactionalertdiv: ElementRef;
    @Output("walletDetailsMenuFun") walletDetailsMenuFun: EventEmitter<any> = new EventEmitter();
    private onDestroy$: Subject<void> = new Subject<void>();
    public isWalletTab: boolean = false;

    constructor(
        private modalService: NgbModal,
        private titleService: Title,
        public txHistoryService: TxHistoryService,
        private router: Router,
        @Inject(LOCALE_ID) private locale: string
    ) {
        this.titleService.setTitle(this.pageTitle);
        this.searchChanged.pipe(debounceTime(2000)).subscribe(() => {
            this.filter = this.txHistoryService.currentSearchString;
            this.page = 1;
            this.getTxHistoryBySearchTerm();
            this.getTxCountBySearchTerm();
        });
        const url = this.router.url;
        this.isWalletTab = url.includes('wallets');
    }

    onLoadData() {
        this.getTxHistory(this.page, this.pageSize, this.currentStatus, this.fromDate, this.toDate, this.walletDetailWalletId);
        this.getTxCount(this.walletDetailWalletId, this.fromDate, this.toDate, this.filter);
    }

    public filterTxByStatus(status) {
        this.currentStatus = status;
        switch (status) {
            case TxStatusOptions.CONFIRMED:
                this.successButtonActive = true;
                this.pendingButtonActive = false;
                this.failedButtonActive = false;
                break;
            case TxStatusOptions.PENDING:
                this.successButtonActive = false;
                this.pendingButtonActive = true;
                this.failedButtonActive = false;
                break;
            case TxStatusOptions.FAILED:
                this.successButtonActive = false;
                this.pendingButtonActive = false;
                this.failedButtonActive = true;
                break;
            default:
                console.log("Unknown status");
        }
        this.getTxHistory(this.page, this.pageSize, status, this.fromDate, this.toDate, this.walletDetailWalletId, this.filter);
    }

    onPageChange(pageNumber: number) {
        this.loading = true;
        this.page = pageNumber;
        this.txs = [];
        this.getTxHistory(pageNumber, this.pageSize, this.currentStatus, this.fromDate, this.toDate, this.walletDetailWalletId, this.filter);
    }

    getTxHistory(pageNumber: number, pageSize: number, status: TxStatusOptions, from?: NgbDate, to?: NgbDate, walletId?: string, searchTerm?: string) {
        this.loading = true;
        this.txHistoryService.getTxHistory(pageNumber ?? 1, pageSize, status, from, to, walletId, searchTerm).subscribe(res => {
            if (res == null) return;
            this.paginationMetaData = res?.paginationMeta;
            this.getReArrangedData(res.txs, res?.paginationMeta)
            this.loading = false;
        });
    }

    getTxCount(walletId?: string, from?: Date, to?: Date, searchTerm?: string) {
        this.txHistoryService.getTxCount(walletId, from, to, searchTerm).subscribe(res => {
            this.txSuccessCount = res?.CONFIRMED;
            this.txPendingCount = res?.PENDING;
            this.txFailCount = res?.FAILED;
        });
    }

    ngOnInit(): void {
        this.onLoadData();
    }

    showHistoryWalletDetail() {
        this.walletDetailsMenuFun.emit();
    }

    public onDateRangeSelection(range: { from: Date; to: Date }) {
        this.fromDate = range.from;
        this.toDate = range.to;
        this.loading = true;
        if (this.fromDate != null || this.toDate != null) {
            this.fromDate = this.fromDate ? formatDate(new Date(this.fromDate), "yyyy-MM-dd", this.locale)
                : null;
            if (this.toDate) {
                this.toDate = this.toDate ? formatDate(new Date(this.toDate), "yyyy-MM-dd", this.locale)
                    : null;
            } else {
                this.toDate = formatDate(new Date(this.fromDate), "yyyy-MM-dd", this.locale)
            }
        }
        this.page = 1;
        this.getTxHistory(this.page, this.pageSize, this.currentStatus, this.fromDate, this.toDate, this.walletDetailWalletId, this.filter);
        this.getTxCount(this.walletDetailWalletId, this.fromDate, this.toDate, this.filter);
    }

    getTxHistoryBySearchTerm(): void {
        this.getTxHistory(this.page, this.pageSize, this.currentStatus, this.fromDate, this.toDate, this.walletDetailWalletId, this.filter);
    }

    getTxCountBySearchTerm(): void {
        this.getTxCount(this.walletDetailWalletId, this.fromDate, this.toDate, this.filter);
    }


    search(searchTerm: string): void {
        this.searchChanged.next(searchTerm);
    }


    onAudioPlay() {
        // let sound = new Howl({
        //   src: ['../../assets/audio/ka-ching.mp3']
        // });
        // sound.play();
    }

    Downloadcsv() {
        if (this.fromDate != null || this.toDate != null) {
            var from: Date = new Date(new Date(this.fromDate.toString()).setHours(0, 0, 0, 0))
            if (this.toDate) {
                var to: Date = new Date(new Date(this.toDate.toString()).setHours(23, 59, 59, 999))
            } else {
                var to: Date = new Date(new Date(this.fromDate.toString()).setHours(23, 59, 59, 999))
            }
            var fromDate = from.toISOString()
            var toDate = to.toISOString()
        } else {
            fromDate = null;
            toDate = null;
            return;
        }
        const pageSize = 8000;
        this.txHistoryService.getTxHistory(this.page, pageSize, this.currentStatus, this.fromDate, this.toDate, this.walletDetailWalletId).subscribe(async (res) => {
            let csvvalues = res.txs;
            if (this.transactions_component_for == 'wallet_details') {
                csvvalues = csvvalues.filter(obj => obj.walletid == this.walletDetailWalletId);
            }
            if (csvvalues.length < 1) {
                this.modalService.open(this.transactionalertdiv, {ariaLabelledBy: 'modal-basic-title', centered: true})
                return;
            }
            let finalData = [];

            csvvalues = this.getReArrangedData(csvvalues);

            csvvalues.forEach((element) => {
                let obj = {};
                obj["ID"] = element.id || '';
                obj["Provider"] = environment.buildName;
                obj["Asset"] = element.coin.toUpperCase() || '';
                obj["Type"] = capitalizeFirstLetter(element.type) || '';
                obj["Amount"] = element.effectivechange || '';
                obj["Fee"] = element.fee || '';
                obj["USD Amount"] = element.effectivechangeusd || '';
                obj["Transaction Hash"] = element.txid || '';
                obj["From"] = `${element?.fromToAddress?.fromName} (${element?.fromToAddress?.fromAddress})`;
                obj["To"] = `${element?.fromToAddress?.toName} (${element?.fromToAddress?.toAddress})`;
                obj["Memo/Tag"] = (element.raw) ? element.raw : '-';
                obj["Time"] = getTimeInLocalimeZone(element.timestamp, 'DD-MM-YYYY HH:mm z') || '';
                obj["Status"] = (element.status.toLowerCase() === 'confirmed') ? 'Success' : element.status;
                finalData.push(obj);
            });
            let keys = ["ID", "Provider", "Asset", "Type", "Amount", "Fee", "USD Amount", "Transaction Hash", "From", "To", "Memo/Tag", "Time", "Status"]
            csvGeneration(finalData, keys, "Transaction_export");
            this.downloading = false;
        }, error => {
            this.downloading = false;
        })
    }

    getReArrangedData(txs: Transaction[], paginationMeta?: PaginationMeta) {
        this.loading = true;
        this.txs = []
        if (txs) {
            if (txs && txs?.length > 0 && this.txs && this.txs?.length > 0) {
                if (this.txs[0].txid != txs[0].txid) this.onAudioPlay();
            }

            txs.forEach(element => {
                const _fromToAddress: any = {
                    fromName: '',
                    fromAddress: '',
                    toName: '',
                    toAddress: ''
                }
                if (element.type == 'send' || element.type == 'pending') {
                    _fromToAddress.fromName = element.wallet.name
                    _fromToAddress.fromAddress = element.wallet.address
                    _fromToAddress.toName = (element.policyName) ? element.policyName : 'Unknown'
                    _fromToAddress.toAddress = element.externaladdress
                } else if (element.type == 'receive') {
                    _fromToAddress.fromName = (element.policyName) ? element.policyName : 'Unknown'
                    _fromToAddress.fromAddress = element.externaladdress
                    _fromToAddress.toName = element.wallet.name
                    _fromToAddress.toAddress = element.wallet.address
                } else if (element.type == 'internal' && element.externaladdress == 'self') {
                    _fromToAddress.fromName = element.wallet.name
                    _fromToAddress.fromAddress = element.wallet.address
                    _fromToAddress.toName = element.wallet.name
                    _fromToAddress.toAddress = element.wallet.address
                } else if (element.type == 'internal' && element.externaladdress != 'self') {
                    _fromToAddress.fromName = element.policyName == "self" ? element.wallet.name : (element.policyName? element.policyName: "Unknown")
                    _fromToAddress.fromAddress = element.externaladdress
                    _fromToAddress.toName = element.wallet.name? element.wallet.name: "Unknown"
                    _fromToAddress.toAddress = (element.wallet.address) ? element.wallet.address : 'Unknown'
                }
                element.fromToAddress = _fromToAddress

            });
            this.txs = txs;
        }
        this.loading = false;
        console.log(txs)
        return txs;
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.txHistoryService.currentSearchString = '';
    }

    navigateToExplorer(explorerLink) {
        if (explorerLink && explorerLink.explorerLink !== "") {
            window.open(explorerLink, '_blank');
        }
    }
}