import { ExternalConfigCache } from "configcat-common";
var LocalStorageCache = /** @class */function () {
  function LocalStorageCache(storage) {
    this.storage = storage;
  }
  LocalStorageCache.setup = function (kernel, localStorageGetter) {
    var localStorage = (localStorageGetter !== null && localStorageGetter !== void 0 ? localStorageGetter : getLocalStorage)();
    if (localStorage) {
      kernel.defaultCacheFactory = function (options) {
        return new ExternalConfigCache(new LocalStorageCache(localStorage), options.logger);
      };
    }
    return kernel;
  };
  LocalStorageCache.prototype.set = function (key, value) {
    this.storage.setItem(key, toUtf8Base64(value));
  };
  LocalStorageCache.prototype.get = function (key) {
    var configString = this.storage.getItem(key);
    if (configString) {
      return fromUtf8Base64(configString);
    }
  };
  return LocalStorageCache;
}();
export { LocalStorageCache };
export function getLocalStorage() {
  var testKey = "__configcat_localStorage_test";
  try {
    var storage = window.localStorage;
    storage.setItem(testKey, testKey);
    var retrievedItem = void 0;
    try {
      retrievedItem = storage.getItem(testKey);
    } finally {
      storage.removeItem(testKey);
    }
    if (retrievedItem === testKey) {
      return storage;
    }
  } catch (err) {/* intentional no-op */}
  return null;
}
export function toUtf8Base64(str) {
  str = encodeURIComponent(str);
  str = str.replace(/%([0-9A-F]{2})/g, function (_, p1) {
    return String.fromCharCode(parseInt(p1, 16));
  });
  return btoa(str);
}
export function fromUtf8Base64(str) {
  str = atob(str);
  str = str.replace(/[%\x80-\xFF]/g, function (m) {
    return "%" + m.charCodeAt(0).toString(16);
  });
  return decodeURIComponent(str);
}