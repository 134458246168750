import { Injectable } from '@angular/core';
import { CUSTODY_TYPE, orgType } from '@entities/members';
import { OrgDetails } from '@entities/walletRequest';
import { BehaviorSubject } from 'rxjs';

const bodyFont = getComputedStyle(document.documentElement).getPropertyValue('--c-body-font');

const ThemeData = {
  light: {
    'c-body-font': bodyFont,
  },
  dark: {
    'c-body-font': bodyFont,
  },
}

@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  public org = new BehaviorSubject<OrgDetails>(null);
  currentOrg = this.org.asObservable();
  private theme = new BehaviorSubject('dark');
  changeOrg(org: any) {
   if(org && org[0]){
    org[0]['isCustody'] = (org[0]?.orgType == orgType.sub && org[0].custodyType == CUSTODY_TYPE.CUSTODY)
    this.org.next(org);
   }
   else return;
  }
  changeTheme(theme: string) {
    this.theme.next(theme);
  }
  getTheme(){
    return ThemeData[this.theme.getValue()];
  }
  constructor() { }
}
