var types = {
  Array: function (value) {
    return value !== null && value !== undefined && value.constructor === Array;
  },
  Boolean: function (value) {
    return typeof value === 'boolean';
  },
  Function: function (value) {
    return typeof value === 'function';
  },
  Nil: function (value) {
    return value === undefined || value === null;
  },
  Number: function (value) {
    return typeof value === 'number';
  },
  Object: function (value) {
    return typeof value === 'object';
  },
  String: function (value) {
    return typeof value === 'string';
  },
  '': function () {
    return true;
  }
};

// TODO: deprecate
types.Null = types.Nil;
for (var typeName in types) {
  types[typeName].toJSON = function (t) {
    return t;
  }.bind(null, typeName);
}
module.exports = types;