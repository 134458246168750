// The interfaces below define a subset of Node's EventEmitter (see https://nodejs.org/api/events.html#class-eventemitter),
// so they are (structurally) compatible with instances of EventEmitter.
var NullEventEmitter = /** @class */function () {
  function NullEventEmitter() {
    this.addListener = this.on;
    this.off = this.removeListener;
  }
  NullEventEmitter.prototype.on = function () {
    return this;
  };
  NullEventEmitter.prototype.once = function () {
    return this;
  };
  NullEventEmitter.prototype.removeListener = function () {
    return this;
  };
  NullEventEmitter.prototype.removeAllListeners = function () {
    return this;
  };
  NullEventEmitter.prototype.listeners = function () {
    return [];
  };
  NullEventEmitter.prototype.listenerCount = function () {
    return 0;
  };
  NullEventEmitter.prototype.eventNames = function () {
    return [];
  };
  NullEventEmitter.prototype.emit = function () {
    return false;
  };
  return NullEventEmitter;
}();
export { NullEventEmitter };