import { ConfigCatClient } from "./ConfigCatClient";
import { PollingMode } from "./ConfigCatClientOptions";
import { ConfigCatConsoleLogger, LogLevel } from "./ConfigCatLogger";
import { FlagOverrides, MapOverrideDataSource, OverrideBehaviour } from "./FlagOverrides";
import { setupPolyfills } from "./Polyfills";
setupPolyfills();
/**
 * Returns an instance of `ConfigCatClient` for the specified SDK Key.
 * @remarks This method returns a single, shared instance per each distinct SDK Key.
 * That is, a new client object is created only when there is none available for the specified SDK Key.
 * Otherwise, the already created instance is returned (in which case the `pollingMode`, `options` and `configCatKernel` arguments are ignored).
 * So, please keep in mind that when you make multiple calls to this method using the same SDK Key, you may end up with multiple references to the same client object.
 * @param sdkKey SDK Key to access the ConfigCat config.
 * @param pollingMode The polling mode to use.
 * @param options Options for the specified polling mode.
 */
export function getClient(sdkKey, pollingMode, options, configCatKernel) {
  return ConfigCatClient.get(sdkKey, pollingMode, options, configCatKernel);
}
/**
 * Disposes all existing `ConfigCatClient` instances.
 */
export function disposeAllClients() {
  ConfigCatClient.disposeAll();
}
/**
 * Creates an instance of `ConfigCatConsoleLogger`.
 * @param logLevel Log level (the minimum level to use for filtering log events).
 * @param eol The character sequence to use for line breaks in log messages. Defaults to "\n".
 */
export function createConsoleLogger(logLevel, eol) {
  return new ConfigCatConsoleLogger(logLevel, eol);
}
/**
 * Creates an instance of `FlagOverrides` that uses a map data source.
 * @param map The map that contains the overrides.
 * @param behaviour The override behaviour.
 * Specifies whether the local values should override the remote values
 * or local values should only be used when a remote value doesn't exist
 * or the local values should be used only.
 * @param watchChanges If set to `true`, the input map will be tracked for changes.
 */
export function createFlagOverridesFromMap(map, behaviour, watchChanges) {
  return new FlagOverrides(new MapOverrideDataSource(map, watchChanges), behaviour);
}
export { FetchStatus, FetchResult, FetchError } from "./ConfigFetcher";
export { InMemoryConfigCache, ExternalConfigCache } from "./ConfigCatCache";
/* Public types for end users */
// List types here which are part of the public API of platform-specific SDKs, thus, should be exposed to end users.
// These exports should be re-exported in the entry module of each platform-specific SDK!
export { PollingMode };
export { DataGovernance } from "./ConfigCatClientOptions";
export { LogLevel };
export { FormattableLogMessage } from "./ConfigCatLogger";
export { SettingType, UserComparator, PrerequisiteFlagComparator, SegmentComparator } from "./ConfigJson";
export { SettingKeyValue } from "./ConfigCatClient";
export { User } from "./User";
export { OverrideBehaviour };
export { ClientCacheState, RefreshResult } from "./ConfigServiceBase";