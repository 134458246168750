import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-riskdisclosure',
  templateUrl: './riskdisclosure.component.html',
  styleUrls: ['./riskdisclosure.component.scss']
})
export class RiskdisclosureComponent implements OnInit {
  biuldName = environment.buildName;

  @Output() riskdisclosure: EventEmitter<any> = new EventEmitter();
  @Input() modal;

  constructor() { }

  ngOnInit(): void {
  }

  riskDisclosure($event, key?) {
    this.riskdisclosure.emit({$event, key})
  }
}
