import { __awaiter, __extends, __generator } from "tslib";
import { ClientCacheState, ConfigServiceBase } from "./ConfigServiceBase";
var LazyLoadConfigService = /** @class */function (_super) {
  __extends(LazyLoadConfigService, _super);
  function LazyLoadConfigService(configFetcher, options) {
    var _this = _super.call(this, configFetcher, options) || this;
    _this.cacheTimeToLiveMs = options.cacheTimeToLiveSeconds * 1000;
    var initialCacheSyncUp = _this.syncUpWithCache();
    _this.readyPromise = _this.getReadyPromise(initialCacheSyncUp, function (initialCacheSyncUp) {
      return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              _a = this.getCacheState;
              return [4 /*yield*/, initialCacheSyncUp];
            case 1:
              return [2 /*return*/, _a.apply(this, [_b.sent()])];
          }
        });
      });
    });
    return _this;
  }
  LazyLoadConfigService.prototype.getConfig = function () {
    return __awaiter(this, void 0, void 0, function () {
      function logExpired(logger, appendix) {
        if (appendix === void 0) {
          appendix = "";
        }
        logger.debug("LazyLoadConfigService.getConfig(): cache is empty or expired" + appendix + ".");
      }
      var cachedConfig;
      var _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            this.options.logger.debug("LazyLoadConfigService.getConfig() called.");
            return [4 /*yield*/, this.options.cache.get(this.cacheKey)];
          case 1:
            cachedConfig = _b.sent();
            if (!cachedConfig.isExpired(this.cacheTimeToLiveMs)) return [3 /*break*/, 5];
            if (!!this.isOffline) return [3 /*break*/, 3];
            logExpired(this.options.logger, ", calling refreshConfigCoreAsync()");
            return [4 /*yield*/, this.refreshConfigCoreAsync(cachedConfig)];
          case 2:
            _a = _b.sent(), cachedConfig = _a[1];
            return [3 /*break*/, 4];
          case 3:
            logExpired(this.options.logger);
            _b.label = 4;
          case 4:
            return [2 /*return*/, cachedConfig];
          case 5:
            this.options.logger.debug("LazyLoadConfigService.getConfig(): cache is valid, returning from cache.");
            return [2 /*return*/, cachedConfig];
        }
      });
    });
  };
  LazyLoadConfigService.prototype.refreshConfigAsync = function () {
    this.options.logger.debug("LazyLoadConfigService.refreshConfigAsync() called.");
    return _super.prototype.refreshConfigAsync.call(this);
  };
  LazyLoadConfigService.prototype.getCacheState = function (cachedConfig) {
    if (cachedConfig.isEmpty) {
      return ClientCacheState.NoFlagData;
    }
    if (cachedConfig.isExpired(this.cacheTimeToLiveMs)) {
      return ClientCacheState.HasCachedFlagDataOnly;
    }
    return ClientCacheState.HasUpToDateFlagData;
  };
  return LazyLoadConfigService;
}(ConfigServiceBase);
export { LazyLoadConfigService };